import { pluginFactory } from '@/plugins/plugin'
import Entry, { TYPE } from './Entry.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const EntryPlugin = pluginFactory({
  components: { Entry }
})

export { EntryPlugin, Entry, TYPE }
export default Entry
