<template>
  <v-infinite-scroll :loading="changingPage" class="plk-basic-table" @bottom="nextPage">
    <div class="title" v-if="plkparams.title || plkparams.showFiltersInHeader">
      {{$t(plkparams.title)}} {{obtainFiltersLiteral(filtersVisible)}} <sup @click="clearAll()"><i v-if="obtainFiltersLiteral(filtersVisible)" class="filter fas fa-filter" :title="$t('plk.basictable.clearAll')"></i></sup>
    </div>
    <div class="text-center p-2 loading" v-if="notcompleteconfig"> {{$t('plk.basictable.notcompleteconfig')}} </div>
    <div class="text-center p-2 loading" v-if="showLoadingMsg"> {{$t('plk.basictable.obtainingdata')}} </div>
    <div class="content">
      <table class="u-full-width" v-if="!loading">
        <thead v-if="haveMeasures">
          <tr class="tr-headers" v-for="(row, topId) in topDimensionsInRows" :key="topId">
            <th class="pointer" v-show="numberRowsDimension && !isLastTopDimension(topId)" :colspan="numberRowsDimension">
              <span @click="orderByIdElement((row[0] || {}).parent)">{{convertInTitle((row[0] || {}).parent_label)}}</span>
              <sup v-if="getOrderBy((row[0] || {}).parent)"><i class="fas" :class="getOrderBy((row[0] || {}).parent).order === 'DESC' ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i></sup>
            </th>
            <th :title="$t('plk.basictable.order', { name: convertInTitle(row.label) })" class="pointer" v-show="isLastTopDimension(topId)" v-for="(row, rowsId) in rows" :key="`cols-${rowsId}`">
              <span @click="orderBy(row)">{{convertInTitle(row.label)}}</span>
              <sup v-if="isFiltered(row)"><i @click="clearFilter(row)" class="filter fas fa-filter" :title="$t('plk.basictable.clearfilter')"></i></sup>
              <sup v-if="getOrderBy(row.idElement)"><i class="fas" :class="getOrderBy(row.idElement).order === 'DESC' ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i></sup>
            </th>
            <th class="pointer" v-for="(column, columnId) in row" :key="`columns-${columnId}`" :class="{ 'clear': isFiltered({ idElement: column.parent }) }">
              <span v-if="isLastTopDimension(topId) || (row[columnId - 1] || {}).id !== column.id || (row[columnId + 1] || {}).id !== column.id">
                <span :plkvalue="column.id" @click="applyFilter(column) && orderByIdElement(column.id)">{{convertInTitle(column.id)}}</span>
                <sup v-if="isFiltered({ idElement: column.parent })"><i @click="clearFilter({ idElement: column.parent })" class="filter fas fa-filter" :title="$t('plk.basictable.clearfilter')"></i></sup>
                <sup v-if="getOrderBy(column.id)"><i class="fas" :class="getOrderBy(column.id).order === 'DESC' ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i></sup>
              </span>
            </th>
          </tr>
        </thead>
        <thead v-if="!haveMeasures && numberRowsDimension">
          <tr class="tr-headers">
            <th :title="$t('plk.basictable.order', { name: convertInTitle(col.label) })" class="pointer" v-for="(col, colId) in rows" :key="`cols-${colId}`">
              <span @click="orderBy(col)">{{convertInTitle(col.label)}}</span>
              <sup v-if="isFiltered(col)"><i @click="clearFilter(col)" class="filter fas fa-filter" :title="$t('plk.basictable.clearfilter')"></i></sup>
              <sup v-if="getOrderBy(col.idElement)"><i class="fas" :class="getOrderBy(col.idElement).order === 'DESC' ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i></sup>
            </th>
          </tr>
        </thead>
        <tr class="tr-values" v-for="(rowDimension, rowIndex) in leftDimensions" :key="rowIndex">
          <td :plkvalue="itemRow.id" class="td selectable" v-for="(itemRow, colIndex) in rowDimension" :key="`row-${colIndex}`">
            <span @click.stop="applyFilter(itemRow)">{{convertInTitle(itemRow.id)}} </span>
          </td>
          <td :plkvalue="getValue(colDimension.concat(rowDimension))" class="td selectable" v-for="(colDimension, topId) in topDimensionsInColumns" :key="topId" @click="applyFilters(colDimension.concat(rowDimension))">
            {{getValue(colDimension.concat(rowDimension))}}
          </td>
        </tr>
      </table>
      <div @click="nextPage()" class="text-center pointer" v-if="haveMoreData && !changingPage">{{$t('plk.basictable.loadData')}}</div>
    </div>
  </v-infinite-scroll>
  <!-- </div> -->
</template>

<script>
import Vue from 'vue'
import services from '@/services'
import configuration from './package.json'
import readme from './README.md'
import BasicComponentMixin from '@/mixins/viewer/components/base'
import OrderComponentMixin from '@/mixins/viewer/components/order'
import { Store, PLAKA } from '@/store'
import InfiniteScroll from 'v-infinite-scroll'
import 'v-infinite-scroll/dist/v-infinite-scroll.css'
export const TYPE = services.component.normalizeType(configuration.type)
const DEFAULT_STEP_PAGE = 50
const DEFAULT_INITIAL_PAGE = 30
Vue.use(InfiniteScroll)

export default {
  name: TYPE,
  mixins: [
    BasicComponentMixin,
    OrderComponentMixin
  ],
  data () {
    return {
      loading: true,
      cube: undefined,
      notcompleteconfig: false,
      numberItems: undefined,
      changingPage: false
    }
  },
  computed: {
    pages () {
      return (this.plkparams.pages || []).map(({ type, idElement, position = 0, size = 20 }) => ({ type, idElement, position, size })).filter(e => e.type === this.getConnection().ELEMENT_TYPES.DIMENSION)
    },
    stepItems () {
      return this.numberItems || this.plkparams.initialPage || DEFAULT_INITIAL_PAGE
    },
    dimensions () {
      return this.cube ? this.cube.getAllValues(this.rows.concat(this.columns).map(e => e.idElement), { numberItems: this.stepItems || this.stepItems }) : []
    },
    leftDimensions () {
      const rowsId = this.rows.map(r => r.idElement)
      const hashDimensions = {}
      // Debemos borrar los elementos repetidos, no usamos el producto cartesiano para evitar elementos nulos
      let elements = 0
      const values = []
      this.dimensions.some(d => {
        const filterDimension = d.filter(d => rowsId.indexOf(d.parent) !== -1)
        const key = filterDimension.map(e => e.id).join('-')
        elements += hashDimensions[key] || 0
        if (!hashDimensions[key]) {
          values.push(filterDimension)
          hashDimensions[key] = 1
        }
        return elements >= this.stepItems
      })
      Vue.set(this, 'changingPage', false)
      return values
    },
    topDimensionsInRows () {
      if (!this.cube) {
        return []
      } else {
        return this.cube._transpose(this.topDimensionsInColumns)
      }
    },
    topDimensionsInColumns () {
      if (!this.cube) {
        return []
      } else {
        const topDimensions = this.columns.map(r => this.cube.getDimensionValues(r.idElement)).concat([this.cube.getMeasuresValues()])
        return this.cube._cartesianProduct(topDimensions)
      }
    },
    haveMoreData () {
      return !this.cube ? false : this.stepItems < this.cube.getAllValues().length
    },
    defaultPage () {
      return this.plkparams.stepPage || DEFAULT_STEP_PAGE
    },
    showLoadingMsg () {
      return this.loading && !this.notcompleteconfig
    },
    columns2filter () {
      const conditions2remove = (this.plkparams.conditions || []).filter(e => (e.values || []).length).map(e => e.idElement)
      return this.columns.concat(this.rows).filter(c => !conditions2remove.includes(c.idElement))
    },
    filtersListening () {
      // Escuchamos los cambios de los filtros que no tenemos valor premarcado
      const conditions2watch = (this.plkparams.conditions || []).filter(e => !(e.values || []).length)
      return this.columns2filter.concat(conditions2watch).map(e => e.idElement)
    },
    filtersVisible () {
      let filters = this.connectionStarted ? Store.getters[PLAKA.GETTERS.GET_FILTERS](this.filtersListening) : []
      filters = filters.concat((this.plkparams.conditions || []).filter(e => (e.values || []).length))
      return filters
    },
    filters () {
      return this.connectionStarted ? Store.getters[PLAKA.GETTERS.GET_FILTERS](this.filtersListening) : []
    },
    elements () {
      return this.rows.concat(this.columns).concat(this.measures)
    },
    measures () {
      return this.connectionStarted ? (this.plkparams.measures || []).filter(e => e.type === this.getConnection().ELEMENT_TYPES.MEASURE) : []
    },
    columns () {
      return this.connectionStarted ? (this.plkparams.columns || []).filter(e => e.type === this.getConnection().ELEMENT_TYPES.DIMENSION) : []
    },
    rows () {
      return this.connectionStarted ? (this.plkparams.rows || []).filter(e => e.type === this.getConnection().ELEMENT_TYPES.DIMENSION) : []
    },
    haveMeasures () {
      return this.measures.length > 0
    },
    numberRowsDimension () {
      return this.rows.length
    },
    numberColumnsDimension () {
      return this.columns.length
    }
  },
  methods: {
    isLastTopDimension (index) {
      return (this.topDimensionsInRows.length - 1) === index
    },
    nextPage () {
      if (this.haveMoreData && !this.changingPage) {
        Vue.set(this, 'changingPage', true)
        Vue.set(this, 'numberItems', this.stepItems + this.defaultPage)
      }
    },
    applyFilters (filters = []) {
      if (this.plkparams.onClickRow) {
        this.executeFunction(this.plkparams.onClickRow, filters)
      } else {
        let elements = []
        filters.forEach(f => {
          let element = this.elements.find(c => c.idElement === f.parent)
          if (element) {
            elements.push({ ...element, ...{ values: [f.id] } })
          }
        })
        this.getConnection().applyFilters(elements, { overwrite: false })
      }
    },
    applyFilter ({ parent = '', id = '' } = {}) {
      let element = this.elements.find(c => c.idElement === parent)
      if (!element) {
        console.warn(`Not found element '${id}'`)
        return
      }
      if (this.plkparams.onFilter) {
        this.executeFunction(this.plkparams.onFilter, element)
      } else {
        this.getConnection().applyFilter({ ...element, ...{ values: [id] } }, { overwrite: false })
      }
    },
    onDestroyComponent () {
      this.stopData()
    },
    startData () {
      Vue.set(this, 'notcompleteconfig', false)
      if (this.connectionStarted) {
        this.getConnection().initData(this.elements, this.plkparams.conditions || [], { onInit: this.onInitLoad, onChange: this.onChangeData }, { filters: this.filtersListening, order: this.order, pages: this.pages })
      } else {
        Vue.set(this, 'notcompleteconfig', true)
        console.warn(`Necessary connection to create ${TYPE}`)
      }
    },
    stopData () {
      if (this.connectionStarted) {
        this.getConnection().stopData({ onInit: this.onInitLoad, onChange: this.onChangeData })
      }
    },
    onInitLoad () {
      Vue.set(this, 'loading', true)
    },
    onChangeData (cube) {
      Vue.set(this, 'cube', cube)
      this.cube.setElements({
        rows: this.columns,
        cols: this.rows
      })
      Vue.set(this, 'loading', false)
    },
    getValue (options) {
      if (this.cube) {
        return this.cube.getValueByOptions(options)
      }
      return '-'
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  },
  watch: {
    connectionStarted () {
      if (this.connectionStarted && this.getConnection().type === this.$servicesPLK.connection.TYPE.PLAKA) {
        this.startData()
      }
    }
  }
}
</script>
<style lang="scss">
  .type_basictable {
    overflow-y: auto;
  }
</style>
<style scoped lang="scss">
  @import '@/assets/styles/utilities/_variables.scss';
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  .plk-basic-table {
    overflow-y: auto;
    max-height: 100vh;
    scrollbar-color: transparent transparent !important;
    table {
      th {
        position: sticky;
        top: 0;
        background: white;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      td {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .obtainingData {
        td {
          border: 0;
        }
      }
    }
    .selectable {
      cursor: pointer;
    }
    .clear {
      cursor: pointer;
    }
    thead {
      white-space: nowrap;
    }
    .filter {
      font-size: 0.7em;
      cursor: pointer;
    }
    .table-header {
      background: white;
    }
    .loading {
      background: white;
    }
    .tr-values {
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  @each $prop, $abbrev in (width: w, height: h) {
    @each $size, $length in $sizes {
      .#{$abbrev}-#{$size} .plk-basic-table { #{$prop}: #{$size}vh !important; }
    }
  }
</style>
