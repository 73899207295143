import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware, { plaka } from '@/router/middleware/design'
import { PUBLIC_PATH } from '@/enviroment/global'
import { configRouter } from './common'
import { VIEWS as VIEWS_BASE } from './base'

export const VIEWS = {
  DESIGN: {
    name: 'plk_design',
    path: '/design'
  },
  FILES: {
    name: 'plk_files',
    path: '/files'
  },
  EXPORT: {
    name: 'plk_export',
    path: '/export'
  },
  IMPORT: {
    name: 'plk_import',
    path: '/import'
  },
  PAGE_CODE: {
    name: 'plk_pagecode',
    path: '/pagecode'
  },
  FILE_EDITOR: {
    name: 'plk_fileeditor',
    path: '/fileeditor'
  },
  CMS: {
    LAYER_LIST: {
      name: 'plk_layerlist',
      path: '/layerlist'
    },
    LAYER_CREATOR: {
      name: 'plk_layercreator',
      path: '/layercreator'
    },
    LAYER_UPDATE: {
      name: 'plk_layerupdate',
      path: '/layerupdate'
    },
    DATABASE_LIST: {
      name: 'plk_dblist',
      path: '/dblist'
    },
    DATABASE_CREATOR: {
      name: 'plk_dbcreator',
      path: '/dbcreator'
    },
    DATABASE_UPDATE: {
      name: 'plk_dbupdate',
      path: '/dbupdate'
    }
  }
}
const routes = [
  {
    path: VIEWS.DESIGN.path,
    name: VIEWS.DESIGN.name,
    component: () => import(/* webpackChunkName: "design" */ '../views/design/Design.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.FILES.path,
    name: VIEWS.FILES.name,
    component: () => import(/* webpackChunkName: "files" */ '../views/design/Files.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.EXPORT.path,
    name: VIEWS.EXPORT.name,
    component: () => import(/* webpackChunkName: "export" */ '../views/design/Export.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.IMPORT.path,
    name: VIEWS.IMPORT.name,
    component: () => import(/* webpackChunkName: "import" */ '../views/design/Import.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.PAGE_CODE.path,
    name: VIEWS.PAGE_CODE.name,
    component: () => import(/* webpackChunkName: "pagecode" */ '../views/design/PageCode.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.FILE_EDITOR.path,
    name: VIEWS.FILE_EDITOR.name,
    component: () => import(/* webpackChunkName: "fileeditor" */ '../views/design/FileEditor.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS_BASE.LOGOUT.path,
    name: VIEWS_BASE.LOGOUT.name,
    component: () => import(/* webpackChunkName: "logoutcms" */ '../views/logout/Logout.vue'),
    meta: {}
  },
  {
    path: VIEWS.CMS.LAYER_LIST.path,
    name: VIEWS.CMS.LAYER_LIST.name,
    component: () => import(/* webpackChunkName: "layerlist" */ '../views/design/cms/layers/List.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.CMS.LAYER_CREATOR.path,
    name: VIEWS.CMS.LAYER_CREATOR.name,
    component: () => import(/* webpackChunkName: "layercreator" */ '../views/design/cms/layers/Creator.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.CMS.LAYER_UPDATE.path,
    name: VIEWS.CMS.LAYER_UPDATE.name,
    component: () => import(/* webpackChunkName: "layerupdate" */ '../views/design/cms/layers/Update.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.CMS.DATABASE_LIST.path,
    name: VIEWS.CMS.DATABASE_LIST.name,
    component: () => import(/* webpackChunkName: "dblist" */ '../views/design/cms/db/List.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.CMS.DATABASE_CREATOR.path,
    name: VIEWS.CMS.DATABASE_CREATOR.name,
    component: () => import(/* webpackChunkName: "dbcreator" */ '../views/design/cms/db/Creator.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.CMS.DATABASE_UPDATE.path,
    name: VIEWS.CMS.DATABASE_UPDATE.name,
    component: () => import(/* webpackChunkName: "dbupdate" */ '../views/design/cms/db/Creator.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS_BASE.LOGIN.path,
    name: VIEWS_BASE.LOGIN.name,
    component: () => import(/* webpackChunkName: "loginDesign" */ '../views/login/Design.vue'),
    meta: {
      middleware: [plaka]
    }
  },
  {
    path: VIEWS_BASE.ABOUT.path,
    name: VIEWS_BASE.ABOUT.name,
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS_BASE.VIEWER.path,
    name: VIEWS_BASE.VIEWER.name,
    component: () => import(/* webpackChunkName: "designviewer" */ '../views/viewer/Design.vue'),
    meta: {
      middleware
    }
  },
  {
    path: '/',
    redirect: VIEWS.DESIGN.path
  },
  {
    path: '*',
    name: 'allpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
    meta: {
      middleware
    }
  }
]

const initRouter = () => {
  Vue.use(VueRouter)
  let router = new VueRouter({
    mode: 'history',
    base: PUBLIC_PATH,
    routes
  })
  configRouter(router)
  return router
}
export default initRouter
