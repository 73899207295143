import httpClient from '../http'
import { APP_FILTER_RELATIONS_CONFIG_FILE } from '@/enviroment/app'

let _relationsCache

const _loadRelations = async () => {
  try {
    if (_relationsCache) return _relationsCache
    const response = await httpClient({
      method: 'get',
      url: APP_FILTER_RELATIONS_CONFIG_FILE
    })
    _relationsCache = response.data === undefined || !(response.data instanceof Object) ? {} : response.data
  } catch (e) {
    _relationsCache = {}
  }
  return _relationsCache
}
const purgeCache = () => {
  _relationsCache = undefined
}
const getRelations = async () => {
  let relations = await _loadRelations()
  return relations
}

export default {
  purgeCache,
  getRelations
}
