import { addNamespaceInOperations } from '../helper'
import servicePlaka from '@/services/connection/plaka'

import Vue from 'vue'

export const MUTATIONS_METHODS = {
  SET_FILTERS: 'setDimension',
  REMOVE_FILTERS: 'removeFilters'
}

export const mutations = {
  [MUTATIONS_METHODS.SET_FILTERS] (state, { filters = {}, fields2notify }) {
    Vue.set(state, 'filters', filters)
    servicePlaka.evaluate(fields2notify)
  },
  [MUTATIONS_METHODS.REMOVE_FILTERS] (state, { fields2notify }) {
    Vue.set(state, 'filters', {})
    servicePlaka.evaluate(fields2notify)
  }
}

export const MUTATIONS = addNamespaceInOperations('plaka', MUTATIONS_METHODS)
