import Vue from 'vue'
import _ from 'lodash'

export default {
  data () {
    return {
      order: _.cloneDeep(this.plkparams.order || [])
    }
  },
  methods: {
    getOrderBy (idElement) {
      return this.order.find(o => o.idElement === idElement)
    },
    orderByIdElement (idElement) {
      const element = this.elements.find(measure => (idElement && measure.idElement === idElement))
      if (element) {
        this.orderBy(element)
      }
    },
    orderBy (element = {}) {
      this.stopData()
      let previousOrder = this.getOrderBy(element.idElement)
      if ((!previousOrder && element.idElement) || (previousOrder && previousOrder.idElement !== element.idElement)) {
        previousOrder = Object.assign({ order: 'DESC' }, element)
      } else if (previousOrder && previousOrder.idElement === element.idElement && previousOrder.order === 'DESC') {
        previousOrder = Object.assign({ order: 'ASC' }, element)
      } else {
        previousOrder = undefined
      }
      this.order = this.order.filter(o => o.idElement !== element.idElement)
      if (previousOrder) {
        this.order.push(previousOrder)
      }
      this.startData()
    }
  },
  watch: {
    'plkparams.order' () {
      Vue.set(this, 'order', _.cloneDeep(this.plkparams.order || []))
    }
  }
}
