import base, { init as initBase } from './base'
import xhook from 'xhook'
import { APP_CONNECTION_CONFIG_FILE, APP_FILTER_RELATIONS_CONFIG_FILE } from '@/enviroment/app'

const CONFIG_FILES = {
  'i18n/config.json': {
    'defaultLang': 'es',
    'languages': ['es', 'en']
  },
  'i18n/es.json': {
  },
  'i18n/en.json': {
  }
}
const _internalConnections = []
let _internalFilterRelations = {}

export const setConnection = (connection = {}) => {
  if (!_internalConnections.find(c => c.id === connection.id) && connection.id) {
    _internalConnections.push(connection)
  }
}

export const setConnections = (connections = []) => {
  connections.forEach(connection => setConnection(connection))
}

export const setFilterRelations = (filterRelations = {}) => {
  _internalFilterRelations = filterRelations
}

export const init = () => {
  initBase()
  xhook.before((request, response) => {
    if (request.url === APP_CONNECTION_CONFIG_FILE) {
      response({ status: 200, statusText: 'OK', headers: { 'content-length': JSON.stringify(_internalConnections).length, _overwritedata: true } })
    } else if (request.url === APP_FILTER_RELATIONS_CONFIG_FILE) {
      response({ status: 200, statusText: 'OK', headers: { 'content-length': JSON.stringify(_internalFilterRelations).length, _overwritedata: true } })
    } else if (CONFIG_FILES[request.url]) {
      response({ status: 200, statusText: 'OK', headers: { 'content-length': JSON.stringify(CONFIG_FILES[request.url]).length, _overwritedata: true } })
    } else {
      response()
    }
  })
  base.interceptors.response.use((response) => {
    if (response.headers._overwritedata) {
      if (response.config.url === APP_CONNECTION_CONFIG_FILE) {
        response.data = _internalConnections
      } else if (response.config.url === APP_FILTER_RELATIONS_CONFIG_FILE) {
        response.data = _internalFilterRelations
      } else if (CONFIG_FILES[response.config.url]) {
        response.data = CONFIG_FILES[response.config.url]
      } else {
        response.data = {}
      }
    }
    return response
  })
}

export default base
