<template>
    <div @contextmenu.prevent="$refs.menu.open" class="component" :class="[`type_${normalizeType(plkparams.type)} ${plkparams.parentClass || ''}`, { 'maximize': maximize }]" @mouseleave="showMenu=false">
      <div class="content-menu text-left" v-if="!maximize">
        <div class="menu" :title="$t('designer.objectLoader.menus.open')">
            <i v-if="haveMenu && !showMenu" class="icon-menu fas fa-ellipsis-v pointer" @click="showMenu=true"></i>
            <div class="wrapper">
              <transition name="slide">
                <div v-if="showMenu" class="items">
                  <i @click.stop="showMenu=false" class="fas fa-times" :title="$t('designer.objectLoader.menus.close')"></i>
                  <i @click.stop="maximizeNow()" class="fas fa-expand-alt" :title="$t('designer.objectLoader.menus.expand')"></i>
                  <i v-for="(action, index) in menuActions" :key="index" :class="action.icon" @click.stop="executeAction(action.function)" :title="$t(action.title || '')"/>
                </div>
              </transition>
            </div>
          </div>
      </div>
      <component v-if="!plkparams.plkNoEdited" v-bind:is="instance" :connection="plkparams.connection" :plkparams="plkparams.plkparams" :maximized="maximize"></component>
      <div class="noObject" v-if="plkparams.plkNoEdited">
        <img v-if="packages[plkparams.type]" :src="packages[plkparams.type].icon"/>
        <div>{{plkparams.type}}</div>
      </div>

      <vue-context ref="menu" class="text-left">
        <li><a href="#" @click="editObject()">{{$t('designer.objectLoader.edit')}}</a></li>
        <li><a href="#" @click="embebedObject()">{{$t('designer.objectLoader.embebed')}}</a></li>
        <li><a href="#" @click="moveUp()">{{$t('designer.objectLoader.move.up')}}</a></li>
        <li><a href="#" @click="moveDown()">{{$t('designer.objectLoader.move.down')}}</a></li>
        <li><a href="#" @click="cloneObject()">{{$t('designer.objectLoader.clone')}}</a></li>
        <li><a href="#" @click="deleteObject()">{{$t('designer.objectLoader.delete')}}</a></li>
      </vue-context>
    </div>
</template>
<script>
import Vue from 'vue'
import i18n from './i18n'
import { getContent } from '@/services/http/design'
import VueContext from 'vue-context'
import 'vue-context/dist/css/vue-context.css'
export const EDIT_OBJECT = 'editObject'
export const EMBEBED_OBJECT = 'embebedObject'
export const DELETE_OBJECT = 'deleteObject'
export const CLONE_OBJECT = 'cloneObject'
export const MOVE_LEFT = 'leftObject'
export const MOVE_RIGHT = 'rightObject'
export const MOVE_UP = 'upObject'
export const MOVE_DOWN = 'downObject'

export default {
  name: 'ObjectLoader',
  props: {
    instance: {
      required: true
    },
    position: {
      required: true
    }
  },
  components: {
    VueContext
  },
  data () {
    return {
      showMenu: false,
      packages: {},
      maximize: false
    }
  },
  computed: {
    haveMenu () {
      return (this.plkparams || {}).menu === undefined || Array.isArray((this.plkparams || {}).menu) ? true : this.plkparams.menu
    },
    menuActions () {
      return (this.plkparams || {}).menu && Array.isArray((this.plkparams || {}).menu) ? this.plkparams.menu : []
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.startListenerToReplaceImages()
      this.loadPackage(this.plkparams.type)
      this.startListenerExitFullScreen()
    },
    startListenerExitFullScreen () {
      document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {
          Vue.set(this, 'maximize', true)
        } else {
          Vue.set(this, 'maximize', false)
        }
      })
    },
    startListenerToReplaceImages () {
      Vue.nextTick(() => {
        [...document.getElementsByTagName('img'), ...document.getElementsByTagName('iframe')].forEach(tag => {
          const content = getContent(tag.src)
          if (content) {
            tag.src = content.base64Data
          }
        })
      })
    },
    deleteObject () {
      parent.postMessage({ type: DELETE_OBJECT, position: this.position, object: this.plkparams })
    },
    cloneObject () {
      parent.postMessage({ type: CLONE_OBJECT, position: this.position, object: this.plkparams })
    },
    editObject () {
      parent.postMessage({ type: EDIT_OBJECT, position: this.position, object: this.plkparams })
    },
    embebedObject () {
      parent.postMessage({ type: EMBEBED_OBJECT, position: this.position, object: this.plkparams })
    },
    moveLeft () {
      parent.postMessage({ type: MOVE_LEFT, position: this.position, object: this.plkparams })
    },
    moveRight () {
      parent.postMessage({ type: MOVE_RIGHT, position: this.position, object: this.plkparams })
    },
    moveUp () {
      parent.postMessage({ type: MOVE_UP, position: this.position, object: this.plkparams })
    },
    moveDown () {
      parent.postMessage({ type: MOVE_DOWN, position: this.position, object: this.plkparams })
    },
    executeAction (exec) {
      if (exec) {
        try {
          let fun = new Function(['ctx'], exec)
          return fun.apply(this, [this])
        } catch (e) {
          return exec
        }
      }
    },
    normalizeType (type) {
      return this.$servicesPLK.component.normalizeType(type)
    },
    maximizeNow () {
      this.requestFullscreen(this.$el)
    },
    async requestFullscreen (element) {
      try {
        if (!element) {
          console.error('Element is not defined')
        } else if (element.requestFullscreen) {
          await element.requestFullscreen()
        } else if (element.webkitRequestFullscreen) {
          await element.webkitRequestFullscreen()
        } else if (element.mozRequestFullScreen) {
          await element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          await element.msRequestFullscreen()
        } else {
          alert(this.$t('designer.objectLoader.notsupport.browser'))
        }
      } catch (e) {
        alert(this.$t('designer.objectLoader.notsupport.designmode'))
      }
    }
  },
  watch: {
    'plkparams.type' () {
      this.loadPackage(this.plkparams.type)
    }
  }
}
</script>
<style lang="scss" scoped>

.component {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 0;
  &.maximize {
    background: white;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    margin: 0px !important;
  }
  .menu {
    position: absolute;
    z-index: 99999;
    display: none;
    .button-primary {
      cursor: pointer;
      line-height: 0px;
      padding: 0px 10px;
      width: 35px;
    }
    .btn {
      padding-top: .5rem;
      padding-left: 1rem;
      cursor: pointer;
    }
    .wrapper {
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .items {
      background-color: white;
      border: solid #efefef 1px;
      border-bottom: solid #efefef 1px;
      width: 25px;
      padding: 0 10px;
      border-radius: 0 0 20px 0px;
      i {
        margin: 13px 5px 14px;
        color: black;
        font-size: 1em;
        cursor: pointer;
      }
    }
  }
  .icon-menu {
    padding: 5px;
    min-width: 10px;
    text-align: center;
    border-radius: 2px;
    &.colored {
      background: $color-base;
      color: white;
    }
    .plk {
      font-size: 18px;
    }
  }
  .content-menu {
    min-height: 2rem;
    width: 10rem;
  }
  .content-menu:hover {
    .menu {
      padding: 5px;
      display: inline;
    }
    .menu:hover {
      display: inline;
    }
  }
  .noObject {
      padding: 5px;
      text-align: center;
      border: 1px solid #dee2e6;
      border-radius: 10px;
      margin-left: 5px;
      margin-right: 5px;
      flex-direction: column;
      width: 100vw;
      img {
        max-width: 5%;
      }
    }
}
.slide-enter-active {
  transition: all .6s ease;
}
.slide-leave-active {
  transition: all .6s ease;
}
.slide-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
button.button-primary {
  color: #FFF;
  background-color: #b5b5b5;
  border-color: #efefef;
  &:hover {
    color: #000;
    background-color: #efefef;
    border-color: #b5b5b5;
  }
}
@media (hover: none) and (pointer: coarse) {
  .menu {
    display: block;
  }
}
</style>
