import './initDesign'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/design'
import { APP_VERSION } from '@/enviroment/app'
import { PlakaLibrary, PlakaStore } from '@/plugin'
import mixindesign from '@/mixins/design'

console.info(`plaka-design v${APP_VERSION}`)

Vue.use(PlakaLibrary)

window.VuePLK = new Vue({
  router: router(),
  store: PlakaStore.Store,
  render: h => h(App)
}).$mount('#app')

Vue.mixin(mixindesign)
