import Vue from 'vue'
import httpClient from './http'
import { getUrlParam, PATH_URL } from './index'
import { APP_VIEWS_CONFIG_PATH, APP_EXPORT_ZIP, IS_DESIGN_MODE } from '@/enviroment/app'
import loggerService, { LOG_SUCCESS, LOG_ERROR } from './logger'
import { Store, DESIGN } from '@/store'
import { generateUrl } from '@/plugins/url'
import translationService from './translation'
export const TYPE_INTERNAL_OBJECT = 'internal'
export const TYPE_EXTERNAL_OBJECT = 'external'

let _dashboardCache = {}
let _dashboardURL

const purgeCache = () => {
  _dashboardCache = {}
  _dashboardURL = undefined
}
const _isInternalObject = (type) => {
  return Vue.options.components[normalizeType(type)] !== undefined
}
const getDefinitionType = async (type) => {
  if (!type) {
    loggerService.log('config', `_loadExternalComponent-${type}-Not defined type`, LOG_ERROR)
    return Promise.reject(new Error('Not defined type'))
  } else {
    let response = await httpClient({
      method: 'get',
      url: type
    })
    let definition = response.data
    let { styles = [], main = '' } = definition
    return { styles, main }
  }
}
const _loadExternalComponent = async (type) => {
  let { styles = [], main = '' } = await getDefinitionType(type)
  styles.forEach(cssFile => _injectCSS(generateUrl(cssFile, type)))
  return _injectJavascript(generateUrl(main, type))
}
const _injectJavascript = (url) => {
  if (!url) {
    loggerService.log('config', `_injectJavascript-${url}-Not defined url`, LOG_ERROR)
    return Promise.reject(new Error('Not defined url'))
  }
  const name = url.split('/').slice(-1)[0].replace('.js', '').replace('.umd', '').replace('.min', '').replace(/[^0-9a-z-A-Z ]/g, '')
  if (window[name]) return window[name]

  window[name] = new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.addEventListener('load', () => {
      if (window[name] instanceof Promise) {
        loggerService.log('config', `_loadExternalComponent-${url}-imposible load url`, LOG_ERROR)
        reject(new Error(`Error loading ${url}`))
      } else {
        loggerService.log('config', `_loadExternalComponent-${url}-loadedExternalComponent`, LOG_SUCCESS)
        if (window[name].install) {
          Vue.use(window[name], { translations: translationService })
        }
        resolve(window[name].default || window[name])
      }
    })
    script.addEventListener('error', (e) => {
      loggerService.log('config', `_loadExternalComponent-${url}-${e}`, LOG_ERROR)
      reject(new Error(`Error loading ${url}`))
    })
    script.src = url
    document.head.appendChild(script)
  })
  return window[name]
}
const _injectCSS = (url) => {
  if (window.externalViewerMode) return Promise.resolve()
  if (url && !document.getElementById(url)) {
    let head = document.getElementsByTagName('head')[0]
    let link = document.createElement('link')
    link.id = url
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = url
    link.media = 'all'
    head.appendChild(link)
    // head.insertAdjacentElement('afterbegin', link)
  } else if (!url) {
    loggerService.log('config', `_injectCSS-${url}-Not defined url`, LOG_ERROR)
  }
}
const getView = async (id) => {
  if (IS_DESIGN_MODE) {
    return Store.getters[DESIGN.GETTERS.GET_VIEW](id)
  } else {
    const path2read = (getUrlParam(PATH_URL) || APP_VIEWS_CONFIG_PATH)
    if (_dashboardURL === path2read && _dashboardCache[id]) {
      return _dashboardCache[id]
    }
    _dashboardURL = path2read
    try {
      const response = await httpClient({
        method: 'get',
        url: `${_dashboardURL}/${id}.json`.replace(new RegExp('/+', 'gi'), '/')
      })
      _dashboardCache[id] = response.data || {}
      return _dashboardCache[id]
    } catch (e) {
      console.error(e)
      return Promise.reject(new Error(`Imposible obtain page with id '${id}''`))
    }
  }
}
const normalizeType = (type = '') => {
  if (!type) console.warn('Normalize empty type')
  return type.replace(new RegExp('[/:\\.]', 'gi'), '').toLowerCase()
}
const _loadComponent = (type) => {
  return new Promise((resolve, reject) => {
    if (!type) {
      loggerService.log('config', `loadComponent-${type}-Not defined type`, LOG_ERROR)
      reject(new Error('Not defined type'))
    } else if (_isInternalObject(type)) {
      loggerService.log('config', `loadComponent-${type}-loadedInternalObject`, LOG_SUCCESS)
      resolve({
        type: TYPE_INTERNAL_OBJECT,
        component: Vue.options.components[normalizeType(type)]
      })
    } else {
      _loadExternalComponent(type).then(component => {
        loggerService.log('config', `loadComponent-${type}-loadedExternalComponent`, LOG_SUCCESS)
        resolve({
          type: TYPE_EXTERNAL_OBJECT,
          component
        })
      }).catch(e => {
        loggerService.log('config', `loadComponent-${type}-${e}`, LOG_ERROR)
        reject(e)
      })
    }
  })
}
const loadComponent = (type, object, field) => {
  _loadComponent(type).then((response) => {
    if (object && field && !object[field]) {
      Vue.set(object, field, response.component)
    }
  })
}
const loadPackage = async (type = '') => {
  if (!type) {
    loggerService.log('config', `loadComponent-${type}-Not defined type`, LOG_ERROR)
    throw new Error('Not defined type')
  }
  try {
    const loadedComponent = await _loadComponent(type)
    if (loadedComponent.type === TYPE_INTERNAL_OBJECT) {
      return loadedComponent.component.options.methods.getPackage()
    } else if (loadedComponent.type === TYPE_EXTERNAL_OBJECT) {
      return (loadedComponent.component.default || loadedComponent.component).methods.getPackage()
    } else {
      throw new Error(`Not found type ${type}`)
    }
  } catch (e) {
    console.error(`Not found type ${type}`)
    return {}
  }
}
const loadReadme = async (type = '') => {
  if (!type) {
    loggerService.log('config', `loadComponent-${type}-Not defined type`, LOG_ERROR)
    throw new Error('Not defined type')
  }
  try {
    const loadedComponent = await _loadComponent(type)
    if (loadedComponent.type === TYPE_INTERNAL_OBJECT) {
      return loadedComponent.component.options.methods.getReadme()
    } else if (loadedComponent.type === TYPE_EXTERNAL_OBJECT) {
      return (loadedComponent.component.default || loadedComponent.component).methods.getReadme()
    } else {
      throw new Error(`Not found type ${type}`)
    }
  } catch (e) {
    console.error(`Not found type ${type}`)
    return {}
  }
}
const loadZip = async () => {
  if (!IS_DESIGN_MODE) {
    console.error('Function not allowed to PLKView')
    return Promise.reject(new Error(`IFunction not allowed to PLKView`))
  } else {
    try {
      const response = await fetch(APP_EXPORT_ZIP)
      return response.blob()
    } catch (e) {
      console.error(e)
      return Promise.reject(new Error(`Imposible obtain zip`))
    }
  }
}

export default {
  getView,
  normalizeType,
  loadComponent,
  loadPackage,
  loadReadme,
  loadZip,
  purgeCache,
  getDefinitionType
}
