<template>
  <div>
    <yimo-vue-editor v-model="model" :config="config"></yimo-vue-editor>
  </div>
</template>
<script>
import YimoVueEditor from '@/plugins/yimo-vue-editor'
import fields from '@/mixins/viewer/types/fields'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  components: {
    YimoVueEditor
  },
  mixins: [
    fields
  ],
  computed: {
    config () {
      return {
        lang: {
          bold: this.$t('configuration.types.codehtml.bold'),
          underline: this.$t('configuration.types.codehtml.underline'),
          italic: this.$t('configuration.types.codehtml.italic'),
          forecolor: this.$t('configuration.types.codehtml.forecolor'),
          bgcolor: this.$t('configuration.types.codehtml.bgcolor'),
          strikethrough: this.$t('configuration.types.codehtml.strikethrough'),
          eraser: this.$t('configuration.types.codehtml.eraser'),
          source: this.$t('configuration.types.codehtml.source'),
          quote: this.$t('configuration.types.codehtml.quote'),
          fontfamily: this.$t('configuration.types.codehtml.fontfamily'),
          fontsize: this.$t('configuration.types.codehtml.fontsize'),
          head: this.$t('configuration.types.codehtml.head'),
          orderlist: this.$t('configuration.types.codehtml.orderlist'),
          unorderlist: this.$t('configuration.types.codehtml.unorderlist'),
          alignleft: this.$t('configuration.types.codehtml.alignleft'),
          aligncenter: this.$t('configuration.types.codehtml.aligncenter'),
          alignright: this.$t('configuration.types.codehtml.alignright'),
          link: this.$t('configuration.types.codehtml.link'),
          linkTarget: this.$t('configuration.types.codehtml.linkTarget'),
          text: this.$t('configuration.types.codehtml.text'),
          submit: this.$t('configuration.types.codehtml.submit'),
          cancel: this.$t('configuration.types.codehtml.cancel'),
          unlink: this.$t('configuration.types.codehtml.unlink'),
          table: this.$t('configuration.types.codehtml.table'),
          emotion: this.$t('configuration.types.codehtml.emotion'),
          img: this.$t('configuration.types.codehtml.img'),
          uploadImg: this.$t('configuration.types.codehtml.uploadImg'),
          linkImg: this.$t('configuration.types.codehtml.linkImg'),
          video: this.$t('configuration.types.codehtml.video'),
          width: this.$t('configuration.types.codehtml.width'),
          height: this.$t('configuration.types.codehtml.height'),
          location: this.$t('configuration.types.codehtml.location'),
          loading: this.$t('configuration.types.codehtml.loading'),
          searchlocation: this.$t('configuration.types.codehtml.searchlocation'),
          dynamicMap: this.$t('configuration.types.codehtml.dynamicMap'),
          clearLocation: this.$t('configuration.types.codehtml.clearLocation'),
          langDynamicOneLocation: this.$t('configuration.types.codehtml.langDynamicOneLocation'),
          insertcode: this.$t('configuration.types.codehtml.insertcode'),
          undo: this.$t('configuration.types.codehtml.undo'),
          redo: this.$t('configuration.types.codehtml.redo'),
          fullscreen: this.$t('configuration.types.codehtml.fullscreen'),
          openLink: this.$t('configuration.types.codehtml.openLink'),
          uploadPlaceTxt: this.$t('configuration.types.codehtml.uploadPlaceTxt'),
          uploadTimeoutPlaceTxt: this.$t('configuration.types.codehtml.uploadTimeoutPlaceTxt'),
          uploadErrorPlaceTxt: this.$t('configuration.types.codehtml.uploadErrorPlaceTxt')
        },
        menus: [
          'source',
          '|',
          'bold',
          'underline',
          'italic',
          'strikethrough',
          'eraser',
          'forecolor',
          'bgcolor',
          '|',
          'quote',
          'fontfamily',
          'fontsize',
          'unorderlist',
          'orderlist',
          'alignleft',
          'aligncenter',
          'alignright',
          '|',
          'link',
          'unlink',
          '|',
          'undo',
          'redo',
          'fullscreen'
        ]
      }
    }
  },
  methods: {
    onInitComponent () {
      this.setModel()
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style scoped lang="scss">
</style>
