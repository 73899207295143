'use strict'
import _ from 'lodash'

const CONCATENATION_KEY_SEPARATOR = '.'
export const DIMENSION_ROLES = {
  DIMENSION: 'Dimension',
  MEASURE: 'Measure'
}
export const ELEMENT_TYPES = {
  STATISTIC: 'STATISTIC',
  DIMENSION: 'DIMENSION',
  MEASURE: 'MEASURE',
  VIRTUAL_CONDITION: 'VIRTUAL_CONDITION'
}

export default class ProccessJsonStatWrapper {
  /* Objetos para el dinamismo, aqui introducimos los parámetros y simplemente cargando desde un json-stat los introducirá en las letiables necesarias */
  /* No se usa el objeto de abajo directamente ya que al recorrer un objeto el orden es alfabetico no el que introduzcamos */
  /* SIEMPRE QUE NECESITEMOS QUE LAS DIMENSIONES SE RECORRAN EN ORDEN DEBEREMOS RECORRER ELEMENTS no la DIM */
  _instanceOf = ''
  _statData = {} // Objecto de JPlaka
  _orderColumns = [] // Columnas por el orden solicitadas
  _orderRows = [] // Filas por el orden solicitadas
  _rowsOptions = {} // Opciones de filas
  _columnsOptions = {} // Opciones de columnas
  _allOptions = {} // Todas las opciones
  _orderAsc = []
  _orderDesc = []

  JPLAKA_INSTANCE = 'JPlaka'
  MEASURE_KEY = '[Measures]'

  constructor ({ data, type } = {}) {
    this._statData = data
    this._instanceOf = type
    this._indexOfParent._cache = {}
    this._indexOfParentAndId._cache = {}
  }
  // Algoritmo de carga de dimensiones
  _loadDimensionOptions = () => {
    this._columnsOptions = {}
    this._rowsOptions = {}
    let numberDims = this._statData.keys.length
    for (let pos = 0; pos < numberDims; pos++) {
      let dimension = this._statData.keys[pos]
      if (this._orderColumns.indexOf(dimension) !== -1) { // Esta en columnas
        const values = this.getDimensionValues(dimension, this._getDimensionOrder(dimension))
        this._columnsOptions[dimension] = _.cloneDeep(values)
        this._allOptions[dimension] = _.cloneDeep(values)
      } else if (this._orderRows.indexOf(dimension) !== -1) { // Esta en filas
        const values = this.getDimensionValues(dimension, this._getDimensionOrder(dimension))
        this._rowsOptions[dimension] = _.cloneDeep(values)
        this._allOptions[dimension] = _.cloneDeep(values)
      }
    }
  }
  // Obtener el orden especificado para una dimension
  _getDimensionOrder = (dimension) => {
    return this._orderAsc.indexOf(dimension) !== -1 ? 'asc' : this._orderDesc.indexOf(dimension) > -1 ? 'desc' : ''
  }
  // algoritmo para obtener a partir de un array de posiciones su valor
  _arr2num = (arr = []) => {
    if (this._instanceOf === this.JPLAKA_INSTANCE) {
      return this._statData.values[arr.join(CONCATENATION_KEY_SEPARATOR)]
    } else {
      return -1
    }
  }
  // producto cartesiano de un array de arrays
  _cartesianProduct = (arr = []) => (
    arr.reduce((a, b) => (
      a.map((x) => (
        b.map((y) => (
          x.concat(y)
        ))
      )).reduce((a, b) => (a.concat(b)), [])
    ), [[]])
  )
  _transpose = (arr = []) => arr.length ? arr[0].map((_, colIndex) => arr.map(row => row[colIndex])) : []

  // Obtener los indices a partir del idElement de una dimension
  _getDimIndex = (value) => ((this._statData.dimension[value] || {}).index || [])
  _getDimensionsOptions = (ctx, orderElements, elementOptions, { numberItems } = {}) => {
    let rowInOrder = []
    const keys = ctx.getKeys()
    const positions = orderElements.map(e => keys.indexOf(e))
    const options = Object.values(elementOptions)
    const valueByKey = Object.keys(ctx.getValues())
    valueByKey.slice(0, numberItems || valueByKey.length).forEach(e => {
      const values = e.split('.')
      const value = positions.map(pos => options[pos][values[pos]])
      if (value) {
        rowInOrder.push(value)
      }
    })
    return rowInOrder
  }

  _indexOfParent = (parent) => {
    if (!this._indexOfParent._cache[parent]) this._indexOfParent._cache[parent] = this._statData.keys.indexOf(parent)
    return this._indexOfParent._cache[parent]
  }
  _indexOfParentAndId = (parent, id) => {
    if (!this._indexOfParentAndId._cache[`${parent}_${id}`]) this._indexOfParentAndId._cache[`${parent}_${id}`] = this._getDimIndex(parent).map(index => index.id).indexOf(id)
    return this._indexOfParentAndId._cache[`${parent}_${id}`]
  }

  // Obtener valores a partir las posiciones
  getValueByOptions = (options = []) => {
    let elementArr = [new Array(this._statData.keys.length)]
    options.forEach(opt => {
      let parentPosition = this._indexOfParent(opt.parent)
      let idPosition = this._indexOfParentAndId(opt.parent, opt.id)
      if (parentPosition !== -1 && idPosition !== -1) {
        elementArr[parentPosition] = idPosition
      } else {
        console.error(`Not found element ${opt.parent} with value ${opt.id}`)
      }
    })
    return this._arr2num(elementArr) || '-'
  }
  setElements = ({ cols = [], rows = [], filters = [], asc = [], desc = [] } = {}) => {
    this._orderColumns = [...new Set(cols.map(e => e.type !== ELEMENT_TYPES.MEASURE ? e.idElement : this.MEASURE_KEY))]
    this._orderRows = [...new Set(rows.map(e => e.type !== ELEMENT_TYPES.MEASURE ? e.idElement : this.MEASURE_KEY))]
    this._orderAsc = [...new Set(asc.map(e => e.type !== ELEMENT_TYPES.MEASURE ? e.idElement : this.MEASURE_KEY))]
    this._orderDesc = [...new Set(desc.map(e => e.type !== ELEMENT_TYPES.MEASURE ? e.idElement : this.MEASURE_KEY))]
    this._loadDimensionOptions()
  }
  getRows = () => this._orderRows
  getColumns = () => this._orderColumns
  getDimensionValues = (dimension, order = '') => {
    let indexArr = _.cloneDeep(this._getDimIndex(dimension))
    // let typeData = this.getTypeData(dimension)
    if (order.length > 0) {
      /* this.sortBy(indexArr, {
        desc: order === 'desc',
        parser: (item) => {
          if (typeData === 'number') {
            return Number(item)
          } else if (typeData === 'date') {
            return new Date(item)
          } else {
            return item.toUpperCase()
          }
        }
      }) */
      console.error('Order not implemented yet')
    }
    return indexArr
  }
  getMeasuresValues = () => this.getDimensionValues(this.MEASURE_KEY)
  getRowsValues = ({ numberItems } = {}) => {
    if (this._orderColumns.length > 0) {
      throw new Error('Unable to get information by row if columns exist')
    } else {
      return this._getDimensionsOptions(this, this._orderRows, this._rowsOptions, { numberItems })
    }
  }
  getColumnsValues = ({ numberItems } = {}) => {
    if (this._orderRows.length > 0) {
      throw new Error('Unable to get information by columns if row exist')
    } else {
      return this._getDimensionsOptions(this, this._orderColumns, this._columnsOptions, { numberItems })
    }
  }
  getAllValues = (order, { numberItems } = {}) => {
    let executionOrder = _.cloneDeep(order)
    if (!order) {
      executionOrder = this._orderColumns.concat(this._orderRows)
    }
    return this._getDimensionsOptions(this, executionOrder, this._allOptions, { numberItems })
  }
  getTypeData = (dimension) => (this._statData.dimension[dimension] || {}).type_data
  isEmpty = () => this._statData.keys.length === 0
  getValues = () => (this._statData || {}).values || {}
  getKeys = () => ((this._statData || {}).keys) || []
}
