import { addNamespaceInOperations } from '../helper'

import Vue from 'vue'

export const MUTATIONS_METHODS = {
  SET_USER: 'setUser',
  REMOVE_USER: 'removeUser',
  REMOVE_ALL_USERS: 'removeUsers',
  AUTH_PAGE: 'authPage'
}

export const mutations = {
  [MUTATIONS_METHODS.SET_USER] (state, { id, user }) {
    Vue.set(state.users, id, user)
  },
  [MUTATIONS_METHODS.REMOVE_USER] (state, { id }) {
    Vue.delete(state.users, id)
  },
  [MUTATIONS_METHODS.REMOVE_ALL_USERS] (state) {
    Vue.set(state, 'users', {})
  },
  [MUTATIONS_METHODS.AUTH_PAGE] (state, { url }) {
    Vue.set(state, 'authPage', url)
  }
}

export const MUTATIONS = addNamespaceInOperations('user', MUTATIONS_METHODS)
