import { pluginFactory } from '@/plugins/plugin'
import { CodeHtmlPlugin } from './types/codehtml'
import { ConditionsPlugin } from './types/conditions'
import { ConnectionsPlugin } from './types/connections'
import { DatasetsPlugin } from './types/datasets'
import { ElementsPlugin } from './types/elements'
import { EntryPlugin } from './types/entry'
import { SelectorPlugin } from './types/selector'
import { SimpleElementPlugin } from './types/simpleelement'

export const ConfiguratorPlugins = pluginFactory({
  plugins: {
    CodeHtmlPlugin,
    ConditionsPlugin,
    ConnectionsPlugin,
    DatasetsPlugin,
    ElementsPlugin,
    EntryPlugin,
    SelectorPlugin,
    SimpleElementPlugin
  }
})

export { CodeHtml } from './types/codehtml'
export { Conditions } from './types/conditions'
export { Connections } from './types/connections'
export { Datasets } from './types/datasets'
export { Elements } from './types/elements'
export { Entry } from './types/entry'
export { Selector } from './types/selector'
export { SimpleElement } from './types/simpleelement'
