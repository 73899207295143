import 'skeleton-css/css/normalize.css'
import 'skeleton-css/css/skeleton.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@/assets/styles/custom.scss'
import services from '@/services'
import { APP_NAME, APP_VERSION } from '@/enviroment/app'
import { installFactory } from '@/plugins/plugin'
import { ExamplePlugins } from '@/components/example'
import { BasicPlugins } from '@/components/basic'
import { ConfiguratorPlugins } from '@/components/creator'
import * as PlakaStore from '@/store'
import { PlakaMixin } from '@/mixins/viewer'
import Notifications from 'vue-notification'
import VueMeta from 'vue-meta'
import httpClient from '@/services/http'

const installBase = (Vue, { isView = true, refreshMetadataOnceOnNavigation = true, initWithTranslations = true } = {}) => {
  console.info(`${APP_NAME} v${APP_VERSION}`)
  Vue.config.productionTip = false
  Vue.mixin(PlakaMixin)
  Vue.use(Notifications)
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: refreshMetadataOnceOnNavigation
  })
  if (initWithTranslations) {
    services.translation.init()
  }
  Vue.prototype.$httpPLK = httpClient
  Vue.prototype.$servicesPLK = services
  Vue.prototype.$optionsPLK = { isView, refreshMetadataOnceOnNavigation, initWithTranslations }
  Vue.prototype.$storePLK = PlakaStore
}

const postInstall = (Vue, options = {}) => {
  services.event.emitEvent(services.event.BASIC_ACTIONS.LIBRARY_LOADED, {
    options
  })
}

const install = async (Vue, options = {}) => {
  // Instance in window to do autoinstall
  installBase(Vue, options)
  installFactory({ plugins: [ExamplePlugins, BasicPlugins, ConfiguratorPlugins] })(Vue, options)
  postInstall(Vue, options)
}

// wuilder installer
const PlakaLibrary = {
  install
}

export {
  PlakaLibrary
}
