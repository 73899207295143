<template>
  <div class="plk-date-filter">
    <div class="title">{{plkparams.title}}</div>
    <div :title="$t('plk.datefilter.inconsistentDates.alt')" class="inconsistent" v-if="inconsistentDates">{{$t('plk.datefilter.inconsistentDates.msg')}}</div>
    <i v-if="haveSelectedFilter" @click="clearNow()" :title="$t('plk.datefilter.clearall')" class="fas fa-calendar-times clear"></i>
    <functional-calendar
      v-model="calendarData"
      :configs="calendarConfigs"
      @opened="setFormmatedDates(filters2apply)"
      @closed="setFormmatedDates(filters2apply)"
    >
    </functional-calendar>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import * as format from 'date-format'
import { FunctionalCalendar } from 'vue-functional-calendar'
import services from '@/services'
import configuration from './package.json'
import readme from './README.md'
import BasicComponentMixin from '@/mixins/viewer/components/base'
import OrderComponentMixin from '@/mixins/viewer/components/order'
import { Store, PLAKA } from '@/store'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  components: {
    FunctionalCalendar
  },
  mixins: [
    BasicComponentMixin,
    OrderComponentMixin
  ],
  data () {
    return {
      calendarData: {},
      selected: undefined,
      dimensionValue: [],
      loading: false,
      externalFilters: false,
      selectingDate: false
    }
  },
  computed: {
    inconsistentDates () {
      return this.filters.length !== this.filters2apply.length
    },
    filters () {
      if (this.connectionStarted && this.plkparams.dimension) {
        return Store.getters[PLAKA.GETTERS.GET_FILTERS]([this.plkparams.dimension.idElement])
      } else {
        return []
      }
    },
    lessThan () {
      return this.filters.find(filter => (filter.condition || '').toUpperCase() === 'LESS_THAN')
    },
    moreThan () {
      return this.filters.find(filter => (filter.condition || '').toUpperCase() === 'MORE_THAN')
    },
    dateFormat () {
      return this.plkparams.filterDateFormat || 'dd/MM/yyyy'
    },
    haveSelectedFilter () {
      return this.filters2apply.length > 0
    },
    filters2apply () {
      Vue.set(this, 'selectingDate', true)
      if (this.calendarData.selectedDate) {
        Vue.set(this, 'selectingDate', false)
        return [this.convertToDateCondition(this.calendarData)]
      } else if (this.calendarData.dateRange && this.calendarData.dateRange.start && this.calendarData.dateRange.start.date !== false && this.calendarData.dateRange.end && this.calendarData.dateRange.end.date !== false) {
        Vue.set(this, 'selectingDate', false)
        return [
          this.convertToDateCondition({
            selectedDate: this.calendarData.dateRange.start.date,
            selectedHour: this.calendarData.dateRange.start.hour,
            selectedMinute: this.calendarData.dateRange.start.minute,
            selectedDateTime: this.calendarData.dateRange.start.dateTime,
            condition: 'MORE_THAN'
          }),
          this.convertToDateCondition({
            selectedDate: this.calendarData.dateRange.end.date,
            selectedHour: this.calendarData.dateRange.end.hour,
            selectedMinute: this.calendarData.dateRange.end.minute,
            selectedDateTime: this.calendarData.dateRange.end.dateTime,
            condition: 'LESS_THAN'
          })
        ]
      } else if (this.calendarData.dateRange && this.calendarData.dateRange.clear) {
        Vue.set(this, 'selectingDate', false)
        delete this.calendarData.dateRange.clear
      }
      return []
    },
    calendarConfigs () {
      // Está preparado para poder pasarle por configuración aunque no lo tenemos en el README ya que el funcionamiento
      // del componente no es lo bueno esperable, por lo tanto preferimos dejarlo con la configuración por defecto
      return {
        isDateRange: true,
        isAutoCloseable: true,
        isModal: true,
        dateFormat: 'mm/dd/yyyy',
        isMultiple: true,
        dayNames: [this.$t('plk.datefilter.days.mo'), this.$t('plk.datefilter.days.tu'), this.$t('plk.datefilter.days.we'), this.$t('plk.datefilter.days.th'), this.$t('plk.datefilter.days.fr'), this.$t('plk.datefilter.days.sa'), this.$t('plk.datefilter.days.su')],
        monthNames: [this.$t('plk.datefilter.months.fullname.jan'), this.$t('plk.datefilter.months.fullname.feb'), this.$t('plk.datefilter.months.fullname.mar'), this.$t('plk.datefilter.months.fullname.apr'), this.$t('plk.datefilter.months.fullname.may'), this.$t('plk.datefilter.months.fullname.jun'), this.$t('plk.datefilter.months.fullname.jul'), this.$t('plk.datefilter.months.fullname.aug'), this.$t('plk.datefilter.months.fullname.sep'), this.$t('plk.datefilter.months.fullname.oct'), this.$t('plk.datefilter.months.fullname.nov'), this.$t('plk.datefilter.months.fullname.dec')],
        shortMonthNames: [this.$t('plk.datefilter.months.shortname.jan'), this.$t('plk.datefilter.months.shortname.feb'), this.$t('plk.datefilter.months.shortname.mar'), this.$t('plk.datefilter.months.shortname.apr'), this.$t('plk.datefilter.months.shortname.may'), this.$t('plk.datefilter.months.shortname.jun'), this.$t('plk.datefilter.months.shortname.jul'), this.$t('plk.datefilter.months.shortname.aug'), this.$t('plk.datefilter.months.shortname.sep'), this.$t('plk.datefilter.months.shortname.oct'), this.$t('plk.datefilter.months.shortname.nov'), this.$t('plk.datefilter.months.shortname.dec')]
      }
    },
    dimension () {
      return this.plkparams.dimension || {}
    }
  },
  methods: {
    clearNow () {
      Vue.set(this.calendarData, 'dateRange', { 'clear': true, 'start': { date: false }, 'end': { date: false } })
    },
    convertToDateCondition ({ selectedDate, selectedHour, selectedMinute, selectedDateTime, condition = 'EQUAL' }) {
      if (selectedDate) {
        const date = new Date(selectedDate)
        if (!selectedDateTime && condition === 'MORE_THAN') {
          date.setHours(0)
          date.setMinutes(0)
          date.setSeconds(0)
        } else {
          date.setHours(Number.parseInt(selectedDateTime ? (selectedHour - 1) : '22') + 1) // el date en js es utc
          date.setMinutes(Number.parseInt(selectedDateTime ? selectedMinute : '59'))
          date.setSeconds(59)
        }
        return {
          instance: 'DATE',
          condition,
          values: [format.asString(this.dateFormat, date)]
        }
      }
      return undefined
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    },
    filterNow (filters = []) {
      if (!this.selectingDate && !filters.length) {
        this.clearAll()
      } else if (!this.selectingDate) {
        this.getConnection().applyFilters(filters.map(filter => _.assign(_.cloneDeep(this.dimension), filter)), { overwrite: true })
      }
    },
    isFiltered (date) {
      return this.filters2apply.map(filter => filter.values.includes(date)).includes(true)
    },
    setDateRange (position, dateInstance) {
      const dateValue = `${dateInstance.getMonth() + 1}/${dateInstance.getDate()}/${dateInstance.getFullYear()}`
      const dateRange = _.cloneDeep(this.calendarData.dateRange || {})
      dateRange[position] = { date: dateValue }
      Vue.set(this.calendarData, 'dateRange', dateRange)
    },
    setFormmatedDates (filters) {
      [...this.$el.getElementsByTagName('input')].forEach((input, index) => {
        input.value = filters[index] ? filters[index].values.join('') : ''
      })
    }
  },
  watch: {
    moreThan (moment = { values: [] }) {
      if (moment.values.length && !this.isFiltered(moment.values[0])) {
        const dateInstance = format.parse(this.dateFormat, moment.values[0])
        this.setDateRange('start', dateInstance)
      }
    },
    lessThan (moment = { values: [] }) {
      if (moment.values.length && !this.isFiltered(moment.values[0])) {
        const dateInstance = format.parse(this.dateFormat, moment.values[0])
        this.setDateRange('end', dateInstance)
      }
    },
    filters2apply (filters) {
      this.$nextTick(() => {
        this.setFormmatedDates(filters)
      })
      this.filterNow(filters)
    },
    filters (filters) {
      if (!filters.length && this.filters2apply.length) {
        this.clearNow()
      }
    }
  }
}
</script>

<style scoped>
  .plk-date-filter >>>.vfc-multiple-input input {
    width: 50%;
    margin-bottom: 0px
  }
</style>

<style lang="scss" scoped>
  .plk-date-filter {
    margin-bottom: 2rem;
    .inconsistent {
      color: #d00606;
      font-size: x-small;
      cursor: help;
    }
    .clear {
      position: absolute;
      padding: 12px .8rem 12px .8rem;
      background: #F24735;
      color: white;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
  }
</style>
