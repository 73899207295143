<template>
  <div class="plk-auto-refresh">
  </div>
</template>

<script>
import services from '@/services'
import configuration from './package.json'
import readme from './README.md'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  computed: {
    time () {
      return this.plkparams.time || 5000
    }
  },
  methods: {
    refresh () {
      const _self = this
      setTimeout(() => {
        _self.getConnection().evaluate()
        _self.refresh()
      }, _self.time)
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  },
  watch: {
    connectionStarted () {
      if (this.connectionStarted && this.getConnection().type === this.$servicesPLK.connection.TYPE.PLAKA) {
        this.refresh()
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
