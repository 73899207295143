const generateUrl = (path = '', base = window.location.href) => {
  if (path.startsWith('http')) {
    return path
  } else if (path.startsWith('/')) {
    const match = window.location.href.match('[^/]/[^/]')
    return !match ? `${base}/${path}` : `${base.slice(0, match.index + 1)}/${path}`
  } else {
    return `${base.split('/').slice(0, -1).join('/')}/${path}`
  }
}

export {
  generateUrl
}
