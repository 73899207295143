import Vue from 'vue'
import _ from 'lodash'
import { addNamespaceInOperations } from '../helper'
import { v4 as uuidv4 } from 'uuid'
import services from '@/services'
import { defaultFiles, defaultViews } from './state'

export const MUTATIONS_METHODS = {
  RESTORE_BASE: 'restoreBase',
  CREATE_VIEW: 'createView',
  UPDATE_VIEW: 'updateView',
  DELETE_VIEW: 'deleteView',
  CHANGE_VIEW_NAME: 'chageViewName',
  CLONE_VIEW: 'cloneView',
  ADD_OBJECT: 'addObject',
  UPDATE_OBJECT: 'updateObject',
  MOVE_OBJECT: 'moveObject',
  DELETE_OBJECT: 'deleteObject',
  SET_CONNECTIONS: 'setConnections',
  CREATE_AVAILABLE_COMPONENTS: 'createAvailableComponents',
  DELETE_AVAILABLE_COMPONENTS: 'deleteAvailableComponents',
  CREATE_FILE: 'createFile',
  UPDATE_FILE_CONTENT: 'updateFileContent',
  RENAME_FILE: 'renameFile',
  DELETE_FILE: 'removeFile'
}

export const mutations = {
  [MUTATIONS_METHODS.RESTORE_BASE] (state) {
    Vue.set(state, 'views', _.cloneDeep(defaultViews))
    Vue.set(state, 'files', _.cloneDeep(defaultFiles))
  },
  [MUTATIONS_METHODS.CREATE_AVAILABLE_COMPONENTS] (state, { id = '', name }) {
    id = id.toLowerCase()
    if (id.startsWith('http') && !state.externalComponents.find(e => e.id === id)) {
      state.externalComponents.push({ id, name })
    }
  },
  [MUTATIONS_METHODS.DELETE_AVAILABLE_COMPONENTS] (state, { id = '' }) {
    id = id.toLowerCase()
    if (id.startsWith('http')) {
      Vue.set(state, 'externalComponents', state.externalComponents.filter(e => e.id !== id))
    }
  },
  [MUTATIONS_METHODS.DELETE_VIEW] (state, { view = '' }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (state.views[viewName]) {
      Vue.delete(state.views, viewName)
    }
  },
  [MUTATIONS_METHODS.CREATE_VIEW] (state, { view = '', metaInfo = {}, actions = [], viewClass = {}, components = [] }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (!state.views[viewName]) {
      Vue.set(state.views, viewName, {
        metaInfo,
        viewClass,
        actions,
        components
      })
    }
  },
  [MUTATIONS_METHODS.UPDATE_VIEW] (state, { view = '', metaInfo, viewClass, actions, components }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (state.views[viewName]) {
      Vue.set(state.views, viewName, {
        metaInfo: metaInfo || state.views[viewName].metaInfo,
        actions: actions || state.views[viewName].actions,
        viewClass: viewClass || state.views[viewName].viewClass,
        components: components || state.views[viewName].components
      })
    }
  },
  [MUTATIONS_METHODS.CHANGE_VIEW_NAME] (state, { view = '', newView = '' }) {
    const viewName = view.replace(/[^\w]/gi, '')
    const newViewName = newView.replace(/[^\w]/gi, '')
    if (state.views[viewName] && !state.views[newViewName]) {
      Vue.set(state.views, newViewName, _.cloneDeep(state.views[viewName]))
      Vue.delete(state.views, viewName)
    }
  },
  [MUTATIONS_METHODS.CLONE_VIEW] (state, { view = '' }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (state.views[viewName]) {
      let newView = _.cloneDeep(state.views[viewName])
      this.commit({
        type: MUTATIONS.CREATE_VIEW,
        view: `${viewName}_${uuidv4()}`,
        metaInfo: newView.metaInfo,
        actions: newView.actions,
        viewClass: newView.viewClass,
        components: newView.components
      })
    }
  },
  [MUTATIONS_METHODS.ADD_OBJECT] (state, { view = '', object = {} }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (viewName && state.views[viewName] && object.type) {
      if (Object.keys(object).length === 1) {
        object.plkNoEdited = true
      }
      state.views[viewName].components.push(object)
    }
  },
  [MUTATIONS_METHODS.UPDATE_OBJECT] (state, { view = '', position, object }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (viewName && state.views[viewName]) {
      Vue.set(state.views[viewName].components, position, object)
    }
  },
  [MUTATIONS_METHODS.MOVE_OBJECT] (state, { view = '', lastPosition, newPosition }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (viewName && state.views[viewName] && lastPosition >= 0 && lastPosition < state.views[viewName].components.length && newPosition >= 0 && newPosition < state.views[viewName].components.length) {
      let components = _.cloneDeep(state.views[viewName].components)
      components.splice(newPosition, 0, components.splice(lastPosition, 1)[0])
      Vue.set(state.views[viewName], 'components', components)
    }
  },
  [MUTATIONS_METHODS.DELETE_OBJECT] (state, { view = '', position }) {
    const viewName = view.replace(/[^\w]/gi, '')
    if (viewName && state.views[viewName] && position >= 0 && position < state.views[viewName].components.length) {
      Vue.delete(state.views[viewName].components, position)
    }
  },
  [MUTATIONS_METHODS.CREATE_FILE] (state, { filename, content }) {
    const file2save = !filename ? uuidv4() : state.files[filename] ? `${uuidv4()}_${filename}` : filename
    Vue.set(state.files, file2save, content)
    services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR, { type: 'success', msg: file2save })
  },
  [MUTATIONS_METHODS.UPDATE_FILE_CONTENT] (state, { filename, content }) {
    if (state.files[filename]) {
      Vue.set(state.files, filename, content)
    }
  },
  [MUTATIONS_METHODS.RENAME_FILE] (state, { oldPath, newPath }) {
    if (oldPath !== newPath && state.files[oldPath]) {
      Vue.set(state.files, newPath, state.files[oldPath])
      Vue.delete(state.files, oldPath)
      services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR, { type: 'success', msg: newPath })
    }
  },
  [MUTATIONS_METHODS.DELETE_FILE] (state, { filename }) {
    if (state.files[filename]) {
      Vue.delete(state.files, filename)
      services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR, { type: 'success', msg: filename })
    }
  }
}

export const MUTATIONS = addNamespaceInOperations('design', MUTATIONS_METHODS)
