import Vue from 'vue'
import VueRouter from 'vue-router'
import { configRouter } from './common'
import middleware from '@/router/middleware/base'
import { PUBLIC_PATH } from '@/enviroment/global'
import { LOGOUT_PATH, LOGIN_PATH, ABOUT_PATH } from '@/enviroment/app'

export const VIEWS = {
  ABOUT: {
    name: ABOUT_PATH,
    path: '/plaka'
  },
  LOGIN: {
    name: LOGIN_PATH,
    path: '/login'
  },
  VIEWER: {
    name: 'viewer',
    path: '/:id'
  },
  LOGOUT: {
    name: LOGOUT_PATH,
    path: '/logout'
  }
}
const routes = [
  {
    path: VIEWS.LOGIN.path,
    name: VIEWS.LOGIN.name,
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Base.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.LOGOUT.path,
    name: VIEWS.LOGOUT.name,
    component: () => import(/* webpackChunkName: "logout" */ '../views/logout/Logout.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.ABOUT.path,
    name: VIEWS.ABOUT.name,
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
    meta: {
      middleware
    }
  },
  {
    path: VIEWS.VIEWER.path,
    name: VIEWS.VIEWER.name,
    component: () => import(/* webpackChunkName: "viewer" */ '../views/viewer/Base.vue'),
    meta: {
      middleware
    }
  },
  {
    path: '/',
    redirect: '/index',
    meta: {
      middleware
    }
  },
  {
    path: '*',
    name: 'allpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
    meta: {
      middleware
    }
  }
]

const initRouter = () => {
  Vue.use(VueRouter)
  let router = new VueRouter({
    mode: 'hash',
    base: PUBLIC_PATH,
    routes
  })
  configRouter(router)
  return router
}
export default initRouter
