<template>
  <div class="plk-basic-kpi">
    <div class="title" :style="{ color: titleColor, fontSize: titleSize }" v-if="title.value || plkparams.showFiltersInHeader">
      {{$t(title.value)}} {{obtainFiltersLiteral(filtersVisible)}} <sup @click="clearAll()" v-if="obtainFiltersLiteral(filtersVisible)"><i class="filter fas fa-filter pointer" :title="$t('plk.basictable.clearAll')"></i></sup>
    </div>
    <div class="values p-2" v-for="(value, index) in values" :key="index">
      <i :title="value" class="icon" v-if="icon.class" :class="iconClass" :style="{ color: iconColor, fontSize: iconSize }"></i>
      <span class="value" :class="`item-${index}`" :style="{ color: valueColor, fontSize: valueSize }">
        {{value || '-'}}
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import services from '@/services'
import BasicComponentMixin from '@/mixins/viewer/components/base'
import configuration from './package.json'
import readme from './README.md'
import _ from 'lodash'
import { Store, PLAKA } from '@/store'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  mixins: [
    BasicComponentMixin
  ],
  data () {
    return {
      loading: true,
      cube: undefined
    }
  },
  computed: {
    filtersListening () {
      const conditions2watch = (this.plkparams.conditions || []).filter(e => !(e.values || []).length)
      return this.connectionStarted ? Store.getters[PLAKA.GETTERS.GET_FILTERS](conditions2watch.map(e => e.idElement)) : []
    },
    filtersVisible () {
      const filters = this.filters.concat((this.plkparams.conditions || []).filter(e => (e.values || []).length))
      return filters
    },
    filters () {
      if (this.conditions && this.conditions.length) {
        const filters = _.cloneDeep(this.filtersListening).concat(this.conditions.map(c => {
          if (c.values && !Array.isArray(c.values)) {
            const filtersFound = this.filters.filter(f => f.idElement === c.idElement)
            c.values = this.executeFunction(c.values, filtersFound) || []
          } else if (!c.values) {
            return undefined
          }
          return c
        }).filter(Boolean))
        return filters
      } else {
        return this.filtersListening
      }
    },
    title () {
      return (this.plkparams.title || {})
    },
    value () {
      return (this.plkparams.value || {})
    },
    icon () {
      return (this.plkparams.icon || {})
    },
    titleColor () {
      return this.executeFunction((this.title.color || 'black'), this.cubeValues)
    },
    titleSize () {
      return this.executeFunction((this.title.size || '1.5rem'), this.cubeValues)
    },
    valueColor () {
      return this.executeFunction((this.value.color || 'black'), this.cubeValues)
    },
    valueSize () {
      return this.executeFunction((this.value.size || '1.5rem'), this.cubeValues)
    },
    iconColor () {
      return this.executeFunction((this.icon.color || 'black'), this.cubeValues)
    },
    iconSize () {
      return this.executeFunction((this.icon.size || '1.5rem'), this.cubeValues)
    },
    iconClass () {
      return this.executeFunction(this.icon.class, this.cubeValues)
    },
    values () {
      if (this.loading) {
        return [this.$t('plk.basicKPI.obtainingdata')]
      } else if (!this.cube) {
        return ['-']
      } else {
        return this.cubeValues.map(value => this.convertNumber(value))
      }
    },
    cubeValues () {
      if (this.cube) {
        const values = Object.values(this.cube.getValues())
        const position = parseInt(this.value.item2show)
        return !values.length ? ['-'] : !isNaN(position) ? [values[position]] : values
      } else {
        return []
      }
    },
    measure () {
      return this.plkparams.measure
    },
    conditions () {
      return this.plkparams.conditions || []
    },
    formatNumber () {
      if (!this.plkparams.formatNumber) {
        return {
          decimalSeparator: '.',
          options: [
            { value: 1, symbol: '', digits: 2 },
            { value: 1e3, symbol: 'k', digits: 2 },
            { value: 1e6, symbol: 'M', digits: 2 },
            { value: 1e9, symbol: 'G', digits: 2 },
            { value: 1e12, symbol: 'T', digits: 2 },
            { value: 1e15, symbol: 'P', digits: 2 },
            { value: 1e18, symbol: 'E', digits: 2 }
          ]
        }
      }
      return this.plkparams.formatNumber
    }
  },
  methods: {
    convertNumber (num) {
      if (Object.keys(this.formatNumber).length === 0 || this.formatNumber?.options?.length === 0) return num
      let rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      if (this.formatNumber.decimalSeparator === ',') {
        rx = /,0+$|(,[0-9]*[1-9])0+$/
      }
      const number2parse = Number(num.split(this.formatNumber.decimalSeparator)[0].split((/,|\./)).join(''))
      const item = this.formatNumber.options.slice().reverse().find((item) => number2parse >= item.value)
      return item ? (number2parse / item.value).toFixed(item.digits || 2).replace(rx, '$1') + item.symbol : num
    },
    onDestroyComponent () {
      this.stopData()
    },
    startData () {
      if (this.getConnection() && this.measure && this.measure.type === this.getConnection().ELEMENT_TYPES.MEASURE) {
        this.getConnection().initData([this.measure], this.conditions, { onInit: this.obtainData, onChange: this.obtainData })
      } else {
        console.warn(`Necesary measure type '${this.getConnection().ELEMENT_TYPES.MEASURE}' to create ${TYPE}`)
      }
    },
    stopData () {
      if (this.connectionStarted) {
        this.getConnection().stopData({ onInit: this.onInitLoad, onChange: this.onChangeData })
      }
    },
    obtainData () {
      Vue.set(this, 'loading', true)
      this.getConnection().getDataByPage([this.measure], this.filters).then(this.onChangeData)
    },
    onChangeData (cube) {
      Vue.set(this, 'cube', cube)
      this.cube.setElements({
        rows: this.columns,
        cols: []
      })
      Vue.set(this, 'loading', false)
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  },
  watch: {
    connectionStarted () {
      if (this.connectionStarted && this.getConnection().type === this.$servicesPLK.connection.TYPE.PLAKA) {
        this.startData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
