<template>
  <div class="plk-basic-html" v-html="template">
  </div>
</template>

<script>
import Vue from 'vue'
import services from '@/services'
import configuration from './package.json'
import readme from './README.md'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  data () {
    return {
      template: ''
    }
  },
  methods: {
    async onInitComponent () {
      if ((this.plkparams.template || '').match('\\.html$')) {
        const response = await this.$httpPLK({
          method: 'get',
          url: this.plkparams.template
        })
        Vue.set(this, 'template', response.data)
      } else {
        Vue.set(this, 'template', this.plkparams.template || '')
      }
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style lang="scss">
</style>
