import connectionService, { TYPE } from './connection'
export const LOG_SUCCESS = 'success'
export const LOG_ERROR = 'error'

const log = async (page, operation, type) => {
  const auditConnections = await connectionService.getConnections(TYPE.AUDIT)
  auditConnections.forEach((conn) => conn.audit({ page, operation, type }))
}

export default {
  log
}
