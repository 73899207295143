import httpClient from '../http'
import { APP_CONNECTION_CONFIG_FILE } from '@/enviroment/app'
import { load } from './loadHelper'

let _connectionsCache

const purgeCache = () => {
  _connectionsCache = undefined
}
const _getApi = async ({ type } = {}) => {
  try {
    const apiLoaded = await load({ type })
    return apiLoaded.default
  } catch (e) {
    return {}
  }
}
const _addApiMethods = async (connection = {}) => {
  const api = await _getApi(connection)
  if (api.validateFields && api.validateFields(connection)) {
    return { ...connection, ...api }
  } else {
    if (!api.validateFields) {
      console.warn(`Not implemented 'validateFields' to API type '${connection.type}'`)
    } else {
      console.warn(`Imposible ValidateFields to API type '${connection.type}'`)
    }
    return { ...connection }
  }
}
const _evalConnections = (connections = []) => {
  return connections.map((conn) => {
    Object.keys(conn).forEach((key) => {
      try {
        conn[key] = new Function(`return ${conn[key]}`)()
      } catch (e) {}
    })
    return conn
  })
}
const _loadConnections = async () => {
  try {
    const response = await httpClient({
      method: 'get',
      url: APP_CONNECTION_CONFIG_FILE
    })
    _connectionsCache = _evalConnections(response.data instanceof Array ? response.data : [])
    return _connectionsCache
  } catch (e) {
    console.error(e)
    return Promise.reject(new Error(`Imposible obtain ${APP_CONNECTION_CONFIG_FILE}`))
  }
}
const _getConnections = async () => {
  if (_connectionsCache) {
    return _connectionsCache
  }
  return _loadConnections()
}
const getConnections = async (type) => {
  let connections = await _getConnections()
  connections = type ? connections.filter(conn => conn.type === type) : connections
  try {
    const connsWithApis = await Promise.all(connections.map((conn) => instanceConnection(conn)))
    return connsWithApis || []
  } catch (e) {
    return []
  }
}
const getConnection = async (id) => {
  const connections = await getConnections()
  let connection = connections.find(conn => conn.id === id)
  try {
    if (!connection) {
      throw new Error(`Not connection view with id '${id}'`)
    }
    return connection
  } catch (e) {
    return Promise.reject(e)
  }
}
const validateConnections = async (type) => {
  const connections = await getConnections(type)
  return Promise.all(connections.map(conn => {
    if (!conn.validateConnection) {
      console.warn(`Not implemented validateConnection to type ${conn.type}`)
      return Promise.reject(new Error(`Not implemented validateConnection to type ${conn.type}`))
    }
    return conn.validateConnection()
  }))
}
const instanceConnection = async (conn) => {
  const instance = await _addApiMethods(conn)
  return instance
}

export const TYPE = {
  PLAKA: 'plaka',
  AUDIT: 'audit'
}

export default {
  TYPE,
  getConnection,
  getConnections,
  validateConnections,
  purgeCache,
  instanceConnection
}
