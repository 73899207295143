import { pluginFactory } from '@/plugins/plugin'
import { HelloWorldPlugin } from './HelloWorld'

export const ExamplePlugins = pluginFactory({
  plugins: {
    HelloWorldPlugin
  }
})

export { HelloWorld } from './HelloWorld'
