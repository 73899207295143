import { IS_DESIGN_MODE, IS_EXTERNAL_MODE } from '@/enviroment/app'
import base, { init as initBase } from './base'
import design, { init as initDesign } from './design'
import external, { init as initExternal } from './external'

const init = () => {
  if (IS_DESIGN_MODE) {
    initDesign()
    return design
  } else if (IS_EXTERNAL_MODE) {
    initExternal()
    return external
  } else {
    initBase()
    return base
  }
}

export default init()
