<template>
  <div class="plk-basic-container">
    <ObjectLoader :object="object" :connection="object.connection" :plkparams="object.plkparams" :instance="getInstance(object.type)" v-for="(object, index) in componentsWithInstance" :key="`${Math.random()}-${index}`"/>
  </div>
</template>

<script>
import services from '@/services'
import { IS_DESIGN_MODE } from '@/enviroment/app'
import configuration from './package.json'
import readme from './README.md'
import ObjectLoaderViewer from '@/views/viewer/objectLoaders/base/ObjectLoader'
import ObjectLoaderDesign from '@/views/viewer/objectLoaders/design/ObjectLoader'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  data () {
    return {
      instances: {}
    }
  },
  components: {
    ObjectLoader: IS_DESIGN_MODE ? ObjectLoaderDesign : ObjectLoaderViewer
  },
  computed: {
    components () {
      return (this.plkparams.components || [])
    },
    componentsWithInstance () {
      return this.components.filter(object => this.getInstance(object.type))
    }
  },
  methods: {
    async onInitComponent () {
      this.components.forEach(component => {
        if (!this.getInstance(component.type)) {
          this.$servicesPLK.component.loadComponent(component.type, this.instances, this.normalizeType(component.type))
        }
      })
    },
    normalizeType (type) {
      return this.$servicesPLK.component.normalizeType(type)
    },
    getInstance (type) {
      return this.instances[this.normalizeType(type)]
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style lang="scss">
</style>
