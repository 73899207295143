import { pluginFactory } from '@/plugins/plugin'
import BasicTable, { TYPE } from './BasicTable.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const BasicTablePlugin = pluginFactory({
  components: { BasicTable }
})

export { BasicTablePlugin, BasicTable, TYPE }
export default BasicTable
