import _ from 'lodash'
import { APP_CONNECTION_CONFIG_FILE, APP_STYLES_CONFIG_FILE, APP_COLORS_CONFIG_FILE, APP_JAVASCRIPT_CONFIG_FILE, APP_I18N_CONFIG_FILE, APP_I18N_ES_FILE, APP_I18N_EN_FILE, APP_FILTER_RELATIONS_CONFIG_FILE } from '@/enviroment/app'

export let defaultFiles = {}
defaultFiles[APP_CONNECTION_CONFIG_FILE] = {
  data: '',
  base64Data: '',
  isJSON: true
}
defaultFiles[APP_STYLES_CONFIG_FILE] = {
  data: '',
  base64Data: '',
  isCSS: true
}
defaultFiles[APP_COLORS_CONFIG_FILE] = {
  data: '',
  base64Data: '',
  isCSS: true
}
defaultFiles[APP_JAVASCRIPT_CONFIG_FILE] = {
  data: '',
  base64Data: '',
  isJavascript: true
}
defaultFiles[APP_I18N_CONFIG_FILE] = {
  data: `{
    "defaultLang": "es",
    "languages": ["es", "en"]
  }`,
  base64Data: 'ewogICJkZWZhdWx0TGFuZyI6ICJlcyIsCiAgImxhbmd1YWdlcyI6IFsiZXMiLCAiZW4iXQp9',
  isJSON: true
}
defaultFiles[APP_I18N_EN_FILE] = {
  data: `{}`,
  base64Data: 'e30=',
  isJSON: true
}
defaultFiles[APP_I18N_ES_FILE] = {
  data: `{}`,
  base64Data: 'e30=',
  isJSON: true
}
defaultFiles[APP_FILTER_RELATIONS_CONFIG_FILE] = {
  data: ``,
  base64Data: '',
  isJSON: true
}

export let defaultViews = {}
defaultViews['index'] = {
  metaInfo: {},
  viewClass: '',
  components: []
}

export const state = {
  internalComponents: [
    {
      id: 'BasicTable',
      name: 'Basic Table'
    },
    {
      id: 'BasicKPI',
      name: 'Basic KPI'
    },
    {
      id: 'BasicFilter',
      name: 'Basic Filter'
    },
    {
      id: 'DateFilter',
      name: 'Date Filter'
    },
    {
      id: 'BasicChart',
      name: 'Basic Chart'
    },
    {
      id: 'BasicHtml',
      name: 'Basic Html'
    },
    {
      id: 'AutoRefresh',
      name: 'Auto Refresh'
    },
    {
      id: 'BasicContainer',
      name: 'Multiple components'
    }
  ],
  externalComponents: [],
  views: _.cloneDeep(defaultViews),
  files: _.cloneDeep(defaultFiles)
}
