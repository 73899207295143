<template>
    <div class="select_datasets" v-if="model">
      <div class="options">
        <input :placeholder="$t('configuration.types.datasets.search')" class="w-100" type="text" :value="search" @input="e => search = e.target.value" @focus="searching = true" @blur="searching = false"/>
        <div @mouseover="searching = true" @mouseleave="searching = false" class="items" :class="{ 'visible': search }">
          <div @click="addElement(element)" class="item" v-for="(element, index) in elements" :key="index" :class="{ 'disabled': selectedCube && selectedCube !== element.cube }" :title="selectedCube && selectedCube !== element.cube ? $t('configuration.types.datasets.notposiblejoindata') : $t('configuration.types.datasets.click2add')">
            <span class="suptitle">{{element.cube}}</span> {{element.label}}
            <i class="fas fa-plus pull-right add pointer"></i>
          </div>
          <div class="item text-center" v-if="!elements.length">
            {{$t('configuration.types.datasets.notdatasets')}}
          </div>
        </div>
      </div>
      <draggable class="items" v-model="model" v-bind="dragOptions">
        <transition-group>
          <div :title="$t('configuration.types.datasets.drag2move')" class="item draggable" v-for="(element, index) in model" :key="element.measure.idElement">
            <span class="suptitle">{{selectedCube}}</span>
            <div class="inputs">
              <input type="text" required v-model="element.label" :placeholder="$t('configuration.types.datasets.datasetName')"  />
              <Entry :plkparams="{type: 'color'}" :default="element.backgroundColor" @changeValue="element.backgroundColor = $event.value"></Entry>
              <i @click="removeIndex(index)" class="fas fa-times pull-right remove pointer ml-3"></i>
            </div>
          </div>
        </transition-group>
      </draggable>
      <div class="item text-center" v-if="!connectionObject">
        {{$t('configuration.types.datasets.notselectedConnection')}}
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Entry from '../entry/Entry'
import _ from 'lodash'
import fields from '@/mixins/viewer/types/fields'
import draggable from 'vuedraggable'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  mixins: [
    fields
  ],
  components: {
    draggable,
    Entry
  },
  data () {
    return {
      searching: false,
      search: '',
      connectionId: undefined,
      connectionObject: undefined,
      measures: []
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    elements () {
      const search = (this.search || '').toUpperCase()
      if (search === '*') return (this.measures || []).filter(e => !this.model.find(s => s.measure.label === e.label))
      return (this.measures || []).filter(e => e.label.toUpperCase().indexOf(search) !== -1 && !this.model.find(s => s.measure.label === e.label))
    }
  },
  methods: {
    onInitComponent () {
      this.loadConnectionName()
      this.setModel()
    },
    removeIndex (index) {
      if (index > -1) {
        let tempModel = _.cloneDeep(this.model)
        tempModel.splice(index, 1)
        Vue.set(this, 'model', tempModel)
        this.$emit('unselectCube')
      }
    },
    addElement ({ idElement, label, type, cube }) {
      if (idElement && type) {
        let tempModel = _.cloneDeep(this.model)
        tempModel.push(
          {
            measure: { idElement, type, label },
            label,
            backgroundColor: ''
          }
        )
        Vue.set(this, 'model', tempModel)
        Vue.set(this, 'search', '')
        this.$emit('selectCube', { cube })
      }
    },
    loadConnectionName () {
      const globalParam = this.getGlobalParam(this.plkparams.connection)
      Vue.set(this, 'connectionId', globalParam === undefined ? this.plkparams.connection : globalParam)
    },
    async getMeasures () {
      Vue.set(this, 'measures', [])
      if (this.connectionObject) {
        const _measures = await this.connectionObject.getMeasures()
        Vue.set(this, 'measures', _measures)
      }
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  },
  watch: {
    global () {
      this.loadConnectionName()
    },
    async connectionId () {
      if (this.connectionId) {
        const _connectionApi = await this.$servicesPLK.connection.getConnection(this.connectionId)
        Vue.set(this, 'connectionObject', _connectionApi)
      }
    },
    connectionObject (newValue, oldValue) {
      if (oldValue && newValue.id !== oldValue.id) {
        Vue.set(this, 'model', [])
      }
      this.getMeasures()
    }
  }
}
</script>

<style scoped lang="scss">
  .select_datasets {
    .item {
      position: relative;
      .suptitle {
        position: absolute;
        top: 0;
        font-size: .9rem;
        font-weight: lighter;
      }
    }
    .options {
      .items {
        padding-top: .5rem;
        max-height: 0vh;
        overflow: auto;
        .item {
          position: relative;
          padding-top: 8px;
          font-weight: 600;
          height: 20px;
          &:hover {
            background: #efefef;
          }
          &.disabled{
            cursor: no-drop;
            color: #cecece;
            &:hover {
              background: none;
            }
          }
        }
      }
      .items.visible {
        max-height: 100%;
        -webkit-transition: display 0s ease-in-out;
        -webkit-transition: max-height .5s ease-in-out;
        -moz-transition: max-height .5s ease-in-out;
        -ms-transition: max-height .5s ease-in-out;
        -o-transition: max-height .5s ease-in-out;
        transition: max-height .5s ease-in-out;
      }
    }
    .items {
      .item {
        cursor: pointer;
        &:hover {
          .add {
            display: block;
          }
        }
        .inputs {
          padding-top: 1.4rem;
          display: flex;
          gap: 1rem;
          input {
            width: 70%;
          }
          .color-picker {
            width: 22%;
          }
        }
      }
    }
    .remove {
      &:hover {
        color: #900c3e;
      }
    }
    .add {
      display: none;
      color: green;
    }
    .fas {
      line-height: 25px;
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #efefef;
  }

</style>
