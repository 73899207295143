import { Store, PLAKA } from '@/store'

export default {
  computed: {
    showFiltersInHeader () {
      return this.obtainFiltersLiteral(this.filters)
    },
    filters () {
      return this.connectionStarted ? Store.getters[PLAKA.GETTERS.GET_FILTERS]() : []
    }
  },
  methods: {
    obtainFiltersLiteral (filterToShow = []) {
      if (typeof this.plkparams.showFiltersInHeader === 'boolean') {
        return this.plkparams.showFiltersInHeader ? filterToShow.map(e => (!e.values || e.values.length === 0) ? undefined : `${this.convertInTitle(e.label)}: ${e.values.join(', ')}`).filter(Boolean).join(', ') : ''
      } else {
        let fun = new Function(['ctx', 'filters'], this.plkparams.showFiltersInHeader)
        return fun.apply(this, [this, filterToShow])
      }
    },
    convertInTitle (label = '') {
      let translateValue = this.$t(label)
      if (translateValue !== label) {
        return translateValue
      } else {
        return label.replace(new RegExp('.*\\[(.*)\\].*', 'gi'), '$1')
      }
    },
    clearFilter (element = {}) {
      if (!element.idElement) {
        console.warn(`clearFilter: Not defined idElement`)
        return
      }
      this.getConnection().clearFilter(element)
    },
    clearAll () {
      this.getConnection().clearAll()
    },
    isFiltered ({ idElement }) {
      return this.filters.find(f => f.idElement === idElement)
    },
    executeFunction (exec, element) {
      if (exec) {
        try {
          let fun = new Function(['ctx', 'element'], exec)
          return fun.apply(this, [this, element])
        } catch (e) {
          return exec
        }
      }
    }
  }
}
