import { Store, USER } from '@/store'
import { VIEWS } from '@/router/base'
import { APP_REDIRECT_ON_LOGIN, APP_PAGENAME_REDIRECT_ON_LOGIN } from '@/enviroment/app'
import { LOGIN_REDIRECTION_QUERY, LOGIN_REDIRECTION_NAME } from '@/views/login'
import services from '@/services'

export default async function authViewer ({ next, router, to }) {
  try {
    let connections = await getConnections()
    if (arePublicConnections(connections)) {
      return next()
    }
    if (connections.length === 0 && !isLoginPage(to)) {
      removeUserLogged()
    }
    if (haveConnections(connections)) {
      await services.connection.validateConnections(services.connection.TYPE.PLAKA)
      connections = await getConnections()
      if (isLoginPage(to) && haveConnections(connections)) {
        return router.push({ name: to.query[LOGIN_REDIRECTION_NAME] || APP_PAGENAME_REDIRECT_ON_LOGIN, params: { id: to.query[LOGIN_REDIRECTION_QUERY] || APP_REDIRECT_ON_LOGIN } })
      } else if (!isLoginPage(to) && !haveConnections(connections)) {
        throw Error('Without login')
      }
    } else if (!isLoginPage(to)) {
      throw Error('Without login')
    }
    return next()
  } catch (e) {
    removeUserLogged()
    let query = {}
    query[LOGIN_REDIRECTION_QUERY] = to.params.id
    query[LOGIN_REDIRECTION_NAME] = to.name
    const response = await Store.dispatch({ type: USER.ACTIONS.LOGOUT, router })
    return response
  }
}
const arePublicConnections = (connections) => connections.length > 0 && connections.length === connections.filter(c => c.isPublic).length
const isLoginPage = (to) => to.name === VIEWS.LOGIN.name
const haveConnections = (connections) => Object.keys(Store.getters[USER.GETTERS.GET_ALL_USERS]()).length === [...new Map(connections.map(connection => [connection.id, connection])).values()].length
const removeUserLogged = () => Store.commit(USER.MUTATIONS.REMOVE_ALL_USERS)
const getConnections = async () => services.connection.getConnections(services.connection.TYPE.PLAKA)
