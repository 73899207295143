import { pluginFactory } from '@/plugins/plugin'
import SimpleElement, { TYPE } from './SimpleElement.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const SimpleElementPlugin = pluginFactory({
  components: { SimpleElement }
})

export { SimpleElementPlugin, SimpleElement, TYPE }
export default SimpleElement
