import { pluginFactory } from '@/plugins/plugin'
import BasicContainer, { TYPE } from './BasicContainer.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const BasicContainerPlugin = pluginFactory({
  components: { BasicContainer }
})

export { BasicContainerPlugin, BasicContainer, TYPE }
export default BasicContainer
