import httpClient from '../http'
import { Store, CMS } from '@/store'
export const PLK_TOKEN = 'PLK_TOKEN'
export const PLK_USER = 'PLK_USER'
export const PLK_PASSWORD = 'PLK_PASSWORD'
export const PLK_AUTH0 = 'PLK_AUTH0'

const _getToken = () => {
  const user = Store.getters[CMS.GETTERS.GET_USER]()
  return user ? user.token : undefined
}
const _requestHeader = (conn) => {
  let headers = {}
  if (_getToken()) {
    headers[PLK_TOKEN] = _getToken()
  }
  headers['Content-Type'] = 'application/json'
  return headers
}
const _getBase = async function (url) {
  const response = await httpClient({
    method: 'get',
    baseURL: url
  })
  return response.data
}
const loginInCMS = async function ({ url, username, password, token } = {}) {
  const baseApi = await _getBase(url)
  const action = (baseApi.action || []).find(action => action.params.rel === 'login')
  if (!action) {
    throw new Error('Not found login link')
  }
  Store.commit({ type: CMS.MUTATIONS.SET_USER, host: url, user: {} })
  let headers = {}
  headers[PLK_USER] = username
  headers[PLK_PASSWORD] = password
  if (token) headers[PLK_AUTH0] = token
  const { resource } = await executeAction(action.params.type, action.href, {}, headers)
  Store.commit({ type: CMS.MUTATIONS.SET_USER, host: url, user: Object.assign(resource, { username }) })
}
const validateConnection = async function () {
  if (!_getToken()) {
    throw new Error()
  }
  try {
    const response = await httpClient({
      method: 'get',
      url: '/api/1.0/isLogged',
      baseURL: Store.state.cms.host,
      headers: _requestHeader(this)
    })
    return response.data
  } catch (e) {
    Store.commit(CMS.MUTATIONS.REMOVE_USER)
    throw e
  }
}
const getElementsConnectionByFolder = async function (idFolder = 'ROOT') {
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/folders/${idFolder}/connections`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this)
  })
  return response.data
}
const getFolders = async function (page = 0, filter) {
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/folders`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    params: {
      page,
      filter
    }
  })
  return response.data
}
const getStatistics = async function (folderAbstraction, abstraction) {
  if (!folderAbstraction || !abstraction) {
    console.error('folderAbstraction and abstraction is not defined')
    throw new Error('folderAbstraction and abstraction is not defined')
  }
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/abstraction/statistics`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    params: {
      folderAbstraction,
      abstraction
    }
  })
  return response.data
}
const getFileById = async function (idFile) {
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/files/search`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    params: {
      id: idFile
    }
  })
  return response.data
}
const getElementFile = async function (idFolder, idElement) {
  if (!idFolder || !idElement) {
    throw new Error('Imposible search without idFolder and idElement')
  }
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/folders/${idFolder}/files/${idElement}`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this)
  })
  return response.data
}
const getElementsFileByFolder = async function (idFolder = 'ROOT', page = 0, filter) {
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/folders/${idFolder}/files`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    params: {
      page,
      filter
    }
  })
  return response.data
}
const createConnectionInFolder = async function (idFolder, { name, url, driver, user, pass, dialect, extra } = {}) {
  const response = await httpClient({
    method: 'post',
    url: `/api/1.0/elements/folders/${idFolder}/connections`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    data: { name, url, driver, user, pass, extra }
  })
  return response.data
}
const getConnection = async function (idFolder, idConnection) {
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/folders/${idFolder}/connections/${idConnection}`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this)
  })
  return response.data
}
const getConnectionById = async function (idConnection) {
  const response = await httpClient({
    method: 'get',
    url: `/api/1.0/elements/connections/search`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    params: {
      id: idConnection
    }
  })
  return response.data
}
const createLayerInFolder = async function (idFolder, { name = 'default.xml', file, connection, type = 'eu.argalladas.plaka.abstraction.implementation.plaka.Plakazione' } = {}) {
  const headers = _requestHeader(this)
  headers['Content-Type'] = 'multipart/form-data'
  const data = new FormData()
  data.append('name', name)
  data.append('type', type)
  data.append('file', file)
  data.append('connection', connection)
  const response = await httpClient({
    method: 'post',
    url: `/api/1.0/elements/folders/${idFolder}/files`,
    baseURL: Store.state.cms.host,
    headers,
    data
  })
  return response.data
}
const updateConnection = async function (idFolder, idConnection, connection = {}) {
  const response = await httpClient({
    method: 'put',
    url: `/api/1.0/elements/folders/${idFolder}/connections/${idConnection}`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    data: connection
  })
  return response.data
}
const updateLayer = async function (idFolder, idLayer, layer = {}) {
  const response = await httpClient({
    method: 'put',
    url: `/api/1.0/elements/folders/${idFolder}/files/${idLayer}`,
    baseURL: Store.state.cms.host,
    headers: _requestHeader(this),
    data: layer
  })
  return response.data
}
const updateLayerContent = async function (idFolder, idLayer, file) {
  if (!file || !idLayer || !file) throw new Error('Necessary params')
  const headers = _requestHeader(this)
  headers['Content-Type'] = 'multipart/form-data'
  const data = new FormData()
  data.append('file', file)
  const response = await httpClient({
    method: 'put',
    url: `/api/1.0/elements/folders/${idFolder}/files/${idLayer}/content`,
    baseURL: Store.state.cms.host,
    headers,
    data
  })
  return response.data
}
const uploadDriver = async function (file) {
  if (!Store.getters[CMS.GETTERS.GET_USER]().isAdmin) {
    console.error('User is not admin')
    throw new Error('User is not admin')
  }
  const headers = _requestHeader(this)
  headers['Content-Type'] = 'multipart/form-data'
  const data = new FormData()
  data.append('file', file)
  const response = await httpClient({
    method: 'post',
    url: `/api/1.0/elements/connections/drivers/upload`,
    baseURL: Store.state.cms.host,
    headers,
    data
  })
  return response.data
}
const executeAction = async function (method = 'GET', url, params = {}, headers = {}) {
  if (!url) {
    console.error('Not implemented url')
    throw new Error('Not implemented url')
  }
  const response = await httpClient({
    method,
    url,
    baseURL: Store.state.cms.host,
    headers: Object.assign(headers, _requestHeader(this)),
    params
  })
  return response.data
}

export default {
  loginInCMS,
  validateConnection,
  getFolders,
  getElementsConnectionByFolder,
  getStatistics,
  getFileById,
  getElementFile,
  getElementsFileByFolder,
  createConnectionInFolder,
  getConnection,
  getConnectionById,
  createLayerInFolder,
  updateConnection,
  updateLayer,
  updateLayerContent,
  uploadDriver,
  executeAction
}
