import Vue from 'vue'
import mitt from 'mitt'
import { BASIC_ACTIONS } from './basic'

if (!Vue.prototype.$eventBus) {
  Vue.prototype.$eventBus = mitt()
}

const emitEvent = (type, object) => {
  Vue.prototype.$eventBus.emit(type, { type, object })
}
const onEvent = (type, eventFunc) => {
  if (eventFunc) {
    Vue.prototype.$eventBus.on(type, eventFunc)
  } else {
    console.warn('Imposible onEvent with undefined function')
  }
}
const offEvent = (type, eventFunc) => {
  if (eventFunc) {
    Vue.prototype.$eventBus.off(type, eventFunc)
  } else {
    console.warn('Imposible offEvent with undefined function')
  }
}
export default {
  BASIC_ACTIONS,
  emitEvent,
  onEvent,
  offEvent
}
