import axios from 'axios'
import services from '@/services'
import { Store, USER } from '@/store'
import loggerService, { LOG_SUCCESS, LOG_ERROR } from '../logger'

const httpClient = axios.create()
export const init = () => {
  httpClient.interceptors.request.use((config) => {
    services.event.emitEvent(services.event.BASIC_ACTIONS.START_LOADING_BAR, {})
    return config
  }, (error) => {
    services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR_LOADING_BAR, {})
    return Promise.reject(error)
  })
  httpClient.interceptors.response.use((response) => {
    services.event.emitEvent(services.event.BASIC_ACTIONS.STOP_LOADING_BAR, {})
    loggerService.log('plaka', `${response.config.url}-${response.status}`, LOG_SUCCESS)
    return response
  }, (error) => {
    const response = (error.response || error).config
    loggerService.log('plaka', `${response.url}-${response.status}-${response.statusText}`, LOG_ERROR)
    validateError((error.response || error))
    notifyError((error.response || error))
    services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR_LOADING_BAR, {})
    return Promise.reject(error.response)
  })
}
export const notifyError = ({ status, data }) => {
  const code = ((data || {}).code || '')
  if (code !== 'ERR_401') {
    let msg = ((data || {}).msg || 'error.notNetwork')
    let type = 'error'
    if (status === 404) {
      msg = (data || {}).msg || 'error.notFound'
      type = 'warn'
    }
    services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR, { type, msg })
  }
  services.event.emitEvent(services.event.BASIC_ACTIONS.NETWORK_ERROR, { code })
}
export const validateError = ({ status }) => {
  if (status === 401) {
    Store.commit(USER.MUTATIONS.REMOVE_ALL_USERS)
  }
}

export default httpClient
