<template>
  <div id="app" :class="id">
    <vue-topprogress ref="topProgress" :color="barColor" :height="barHeight"></vue-topprogress>
    <router-view/>
    <notifications group="plk" position="bottom right"/>
    <v-dialog/>
  </div>
</template>

<script>
import Vue from 'vue'
import { APP_NOTIFICATION_TIME, APP_PRINCIPAL_COLOR, IS_DESIGN_MODE } from '@/enviroment/app'
import { PUBLIC_PATH } from '@/enviroment/global'
import VModal from 'vue-js-modal'
import { pleaseWait } from 'please-wait'
import 'please-wait/build/please-wait.css'
import { vueTopprogress } from 'vue-top-progress'
import i18n from './i18n'
Vue.use(VModal, { dialog: true })

export default {
  name: 'app',
  components: {
    vueTopprogress
  },
  data () {
    return {
      pleaseWaitInstance: undefined,
      isLoading: false,
      loadingElements: 0,
      barHeight: 5
    }
  },
  mounted () {
    this.$servicesPLK.event.onEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, this.notifyError)
  },
  beforeUpdate () {
    this.initSplashScreen()
  },
  computed: {
    barColor () {
      return APP_PRINCIPAL_COLOR
    },
    id () {
      return this.$route.params.id || 'index'
    },
    urlIllustration () {
      return `${PUBLIC_PATH}images/ilustracion.svg`
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.event.onEvent(this.$servicesPLK.event.BASIC_ACTIONS.START_LOADING_BAR, this.showLoadingApp)
      this.$servicesPLK.event.onEvent(this.$servicesPLK.event.BASIC_ACTIONS.STOP_LOADING_BAR, this.stopLoadingApp)
      this.$servicesPLK.event.onEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR_LOADING_BAR, this.errorLoadingApp)
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    },
    showLoadingApp () {
      Vue.set(this, 'loadingElements', this.loadingElements + 1)
      this.$refs.topProgress.start()
    },
    stopLoadingApp () {
      const loadingElements = this.loadingElements - 1
      if (loadingElements > 0) {
        Vue.set(this, 'loadingElements', loadingElements)
      } else {
        Vue.set(this, 'loadingElements', 0)
        this.$refs.topProgress.done()
      }
    },
    errorLoadingApp () {
      Vue.set(this, 'loadingElements', 0)
      this.$refs.topProgress.fail()
    },
    initSplashScreen () {
      if (!IS_DESIGN_MODE && !this.isLoading && !this.pleaseWaitInstance) {
        this.pleaseWaitInstance = pleaseWait({
          logo: this.urlIllustration,
          backgroundColor: APP_PRINCIPAL_COLOR,
          loadingHtml: `<p class='loading-message'>Plaka Studio</p>`
        })
        Vue.set(this, 'isLoading', true)
      }
      if (!IS_DESIGN_MODE && this.isLoading && this.pleaseWaitInstance != null) {
        setTimeout(() => this.pleaseWaitInstance.finish(), 1000)
      }
    },
    notifyError ({ type, object }) {
      Vue.notify({
        group: 'plk',
        type: object.type || 'error',
        duration: APP_NOTIFICATION_TIME,
        text: this.$t(object.msg)
      })
    }
  }
}
</script>

<style lang="scss">
  .pg-loading-screen .pg-loading-html {
    margin-top: 1%;
  }
  .loading-message {
    color: #F24735;
    font-weight: bold;
    font-size: 2em;
  }
  .pg-loading-logo {
    width: 50vw;
  }
  @media only screen and (min-width: breakpoint-min('sm', $grid-breakpoints)) {
    .pg-loading-logo {
      width: 25vw;
    }
  }
</style>
