<template>
    <div @click="showColor()" :class="`${entryType}-picker`">
      <input :id="id" :ref="id" v-model="model" :type="entryType" :disabled="plkparams.disabled" :pattern="plkparams.pattern" :required="plkparams.required" class="u-full-width" :placeholder="$t(plkparams.placeholder)" :minlength="plkparams.minlength" :maxlength="plkparams.maxlength"/>
      <span v-if="isColorType"> {{color2show}}</span>
    </div>
</template>

<script>
import fields from '@/mixins/viewer/types/fields'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  mixins: [
    fields
  ],
  computed: {
    entryType () {
      return this.plkparams.type || 'text'
    },
    isColorType () {
      return this.entryType.toLowerCase() === 'color'
    },
    color2show () {
      return this.model || '#000000'
    }
  },
  methods: {
    onInitComponent () {
      this.setModel()
    },
    showColor () {
      if (this.isColorType) {
        this.$refs[this.id].click()
      }
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style scoped lang="scss">
  .color-picker {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    gap: .5rem;
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    height: 23px;
    line-height: 23px;

    [type='color'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 0;
      margin: 0;
      width: 15px;
      height: 15px;
      border: none;
    }

    [type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    [type='color']::-webkit-color-swatch {
      border: none;
    }
  }
</style>
