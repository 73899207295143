<template>
  <div class="plk-basic-chart">
    <div class="title" v-if="plkparams.title || plkparams.showFiltersInHeader">
      {{$t(plkparams.title)}} {{obtainFiltersLiteral(filtersVisible)}} <sup @click="clearAll()"><i v-if="obtainFiltersLiteral(filtersVisible)" class="filter fas fa-filter" :title="$t('plk.basictable.clearAll')"></i></sup>
    </div>
    <div class="text-center p-2 loading" v-if="loading"> {{$t('plk.basicchart.obtainingdata')}} </div>
    <component v-if="instance" v-bind:is="instance" :styles="chartStyles"></component>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
import VueChartJs from 'vue-chartjs'
import BasicComponentMixin from '@/mixins/viewer/components/base'
import OrderComponentMixin from '@/mixins/viewer/components/order'
import { Store, PLAKA } from '@/store'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  mixins: [
    BasicComponentMixin,
    OrderComponentMixin
  ],
  data () {
    return {
      instance: undefined,
      cube: undefined,
      loading: false,
      dimensionFiltered: (this.plkparams.dimension || []).length ? [this.plkparams.dimension[0]] : []
    }
  },
  computed: {
    pages () {
      return (this.plkparams.pages || []).map(({ type, idElement, position = 0, size = 20 }) => ({ type, idElement, position, size })).filter(e => e.type === this.getConnection().ELEMENT_TYPES.DIMENSION)
    },
    filters () {
      const conditions2watch = (this.plkparams.conditions || []).filter(e => !(e.values || []).length)
      return this.connectionStarted ? Store.getters[PLAKA.GETTERS.GET_FILTERS](this.columns.concat(conditions2watch).map(e => e.idElement)) : []
    },
    filtersVisible () {
      const filters = this.filters.concat((this.plkparams.conditions || []).filter(e => (e.values || []).length))
      return filters
    },
    listeningDimensionFiltered () {
      const dimensionFilters = this.connectionStarted ? Store.getters[PLAKA.GETTERS.GET_FILTERS](this.dimensions.map(e => e.idElement)) : []
      if (this.dimensions.length && dimensionFilters.length !== this.dimensionFiltered.length) {
        Vue.set(this, 'dimensionFiltered', dimensionFilters)
      }
      return this.dimensionFiltered
    },
    dataset () {
      if (this.cube) {
        return {
          labels: this.generateLabels(),
          datasets: this.cube.getMeasuresValues().map((measure, measureIndex) => {
            return {
              yAxisID: this.options.scales?.yAxes ? this.plkparams.datasets[measureIndex].measure.idElement : undefined,
              label: this.plkparams.datasets[measureIndex].label,
              borderColor: this.plkparams.datasets[measureIndex].borderColor,
              backgroundColor: this.plkparams.datasets[measureIndex].backgroundColor,
              data: this.generateData(measureIndex)
            }
          })
        }
      }
      return {}
    },
    proccessNumberInTooltip () {
      return this.plkparams.options === 'Doughnut' || this.plkparams.options === 'Pie' || this.plkparams.options === 'PolarArea'
    },
    options () {
      return Object.assign({
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onClick,
        tooltips: !this.processNumberInTooltip ? {} : {
          callbacks: {
            label: (tooltipItem, data) => {
              const partsOfNumber = tooltipItem.value.split('.')
              const number = partsOfNumber[0].replace(/(.)(?=(\d{3})+$)/gi, `$1${this.millesSeparator}`)
              const decimal = partsOfNumber.length === 2 ? `${this.decimalSeparator}${partsOfNumber[1]}` : ''
              return `${number}${decimal}`
            }
          }
        }
      }, this.plkparams.options || {})
    },
    typeChart () {
      return VueChartJs[this.plkparams.type] || VueChartJs['Bar']
    },
    chartStyles () {
      let style = {}
      if (this.maximized) {
        const titleDivs = document.querySelectorAll('.plk-basic-chart .title')
        style['height'] = `${window.innerHeight - (titleDivs.length ? titleDivs[0].clientHeight : 0)}px`
      } else {
        style['height'] = this.plkparams.height || '400px'
      }
      return style
    },
    actualColumn () {
      const numberElements = this.dimensions.length
      let index = 0
      for (const column of this.dimensions) {
        if (index === numberElements - 1 || !this.dimensionFiltered.find(e => e.idElement === column.idElement)) {
          return column
        }
        index++
      }
      return undefined
    },
    decimalSeparator () {
      return this.plkparams.decimalSeparator || ','
    },
    millesSeparator () {
      return this.decimalSeparator === '.' ? ',' : '.'
    },
    dimensions () {
      return (this.plkparams.dimension || [])
    },
    columns () {
      return [this.actualColumn]
    },
    measures () {
      return (this.plkparams.datasets || []).map(e => e.measure)
    },
    elements () {
      return this.columns.concat(this.measures)
    },
    conditions () {
      return this.plkparams.conditions || []
    },
    filtersIds () {
      return this.elements.map(e => e.idElement).concat(this.conditions.map(e => e.idElement))
    }
  },
  methods: {
    generateLabels () {
      let labels = this.cube.getDimensionValues(this.actualColumn.idElement).map(e => this.$t(e.id))
      if (this.options?.limit?.step) labels = labels.slice(0, this.options?.limit?.step)
      if (this.options?.limit?.step && this.options?.limit?.aggregateRest) labels = [...labels, this.$t('OtherData')]
      return labels
    },
    generateData (measureIndex) {
      const data = this.cube.getRowsValues().map((dimension, dimIndex) => (this.cube.getValues()[`${dimIndex}.${measureIndex}`] || '').replaceAll(this.millesSeparator, '').replaceAll(this.decimalSeparator, '.').replace(new RegExp(`[^0-9${this.decimalSeparator}]`, 'gi'), ''))
      let generatedData = _.cloneDeep(data)
      if (this.options?.limit?.step) generatedData = generatedData.slice(0, this.options?.limit?.step)
      if (this.options?.limit?.step && this.options?.limit?.aggregateRest) generatedData = [...generatedData, data.slice(this.options?.limit?.step, data.length).reduce((sum, number) => sum + Number(number), 0)]
      return generatedData
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    },
    onDestroyComponent () {
      this.stopData()
    },
    startData () {
      if (this.connectionStarted && this.getConnection().type === this.$servicesPLK.connection.TYPE.PLAKA) {
        if (this.columns && this.measures) {
          this.getConnection().initData(this.elements, this.conditions.concat(this.dimensions), { onInit: this.onInitLoad, onChange: this.onChangeData }, { order: this.order, pages: this.pages })
        } else {
          console.warn('Necesary dimension param and dataset')
        }
      }
    },
    onInitLoad () {
      Vue.set(this, 'loading', true)
    },
    stopData () {
      if (this.connectionStarted) {
        this.getConnection().stopData({ onInit: this.onInitLoad, onChange: this.onChangeData })
      }
    },
    onChangeData (cube) {
      if (!Object.values(cube._statData.dimension).length || cube._statData.dimension[this.actualColumn.idElement]) {
        // Tenemos que evitar carreras críticas, ya que hacemos un stop puede llegar después el elemento parado
        Vue.set(this, 'cube', cube)
        this.cube.setElements({
          rows: this.columns,
          cols: []
        })
        Vue.set(this, 'loading', false)
      }
    },
    onClick (point, event) {
      if (event[0]) {
        const element = this.cube.getDimensionValues(this.actualColumn.idElement)[event[0]._index]
        if (this.options.onClick) {
          this.executeFunction(this.options.onFilter, element)
        } else {
          this.getConnection().applyFilter({ idElement: element.parent, values: [element.id] }, { overwrite: true })
        }
      }
    },
    executeRenderChart () {
      let _self = this
      Vue.set(this, 'instance', Vue.component('line-chart', {
        extends: _self.typeChart,
        mounted () {
          this.renderChart(_self.dataset, _self.options || {})
        },
        watch: {
          styles () {
            this.renderChart(_self.dataset, _self.options || {})
          }
        }
      }))
    }
  },
  watch: {
    connectionStarted () {
      this.startData()
    },
    dataset () {
      this.executeRenderChart()
    },
    actualColumn () {
      this.stopData()
      this.startData()
    },
    // Tenemos que estar escuchando para que se acutalice el actual simpleelement solo cuando no es necesario y no siempre
    listeningDimensionFiltered () {}
  }
}
</script>

<style scoped lang="scss">
  .plk-basic-chart {
    background: white;
  }
</style>
