const load = async ({ type = '', path = './' } = {}) => {
  try {
    require(`${path}${type}`)
    const loadApi = () => import(`${path}${type}`)
    const apiLoaded = await loadApi()
    return apiLoaded
  } catch (ex) {
    throw Error(`Not defined file for the type '${type}' in path ${path}`)
  }
}

export {
  load
}
