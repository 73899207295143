import _ from 'lodash'
const _getList = (object) => {
  let keys = []
  if (object !== undefined && object !== null) {
    Object.keys(object).map(k => {
      if (typeof object[k] === 'object' && !Array.isArray(object[k])) {
        _getList(object[k]).map(value => {
          keys.push(`${k}.${value}`)
        })
      } else {
        keys.push(k)
      }
    })
  }
  return keys
}
const getListFields = (entry = {}) => {
  let fields = []
  Object.keys(entry).map(key => {
    let value = entry[key]
    if (typeof value === 'object' && !Array.isArray(value)) {
      _getList(entry[key]).map(complex => {
        fields.push(`${key}.${complex}`)
      })
    } else {
      fields.push(key)
    }
  })
  return fields
}
const addValueInKey = (object, key, value) => {
  const items = key.split('.').map(name => {
    const match = name.match(/\[\d+?\]/)
    return {
      name: match ? name.slice(0, match.index) : name,
      usesBrackets: !!match,
      key: match && match[0].slice(1, -1)
    }
  })
  let ref = object
  let parent
  items.forEach(item => {
    ref[item.name] = ref[item.name] || {}
    parent = ref
    ref = ref[item.name]
    if (item.usesBrackets) {
      ref[item.key] = {}
      ref = ref[item.key]
    }
  })
  parent[items[items.length - 1].name] = value
  return object
}
const valueByString = (object = {}, key = '') => {
  let value = _.cloneDeep(object)
  let found = []
  key.split('.').forEach((key) => {
    if (key in value) {
      found.push(key)
      value = value[key]
    }
  })
  return found.join('.') === key ? value : undefined
}
const getHashKeyValue = (entry = {}) => {
  let values = {}
  getListFields(entry).forEach(key => {
    values[key] = valueByString(entry, key)
  })
  return values
}

export {
  getListFields,
  addValueInKey,
  valueByString,
  getHashKeyValue
}
