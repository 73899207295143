import httpClient from '../../http'
import services, { LOG_SUCCESS } from '../../logger'
import filterServices from '../../filters'
import { Store, USER, PLAKA } from '@/store'
import _ from 'lodash'
import { load } from '../loadHelper'
import JPlakaProcess, { DIMENSION_ROLES, ELEMENT_TYPES } from './JPlakaProcess'
export const PLK_TOKEN = 'PLK_TOKEN'
export const PLK_USER = 'PLK_USER'
export const PLK_PASSWORD = 'PLK_PASSWORD'
export const PLK_AUTH0 = 'PLK_AUTH0'

let _listeningObjects = []

const _getToken = (conn) => {
  const user = Store.getters[USER.GETTERS.GET_USER_TO_CONNECTION](conn.id)
  return user ? user.token : undefined
}
const _getHashFilters = (filter = []) => {
  return _.cloneDeep(Store.getters[PLAKA.GETTERS.HASH_FILTERS](filter))
}
const _getListFilters = (filter = []) => {
  return _.cloneDeep(Store.getters[PLAKA.GETTERS.GET_FILTERS](filter))
}
const _requestHeader = (conn) => {
  let headers = {}
  if (_getToken(conn)) {
    headers[PLK_TOKEN] = _getToken(conn)
  }
  headers['Content-Type'] = 'application/json'
  return headers
}
const _getData = async (conn = {}, elements = [], conditions = [], { pages = [], order = [] } = {}) => {
  const response = await httpClient({
    method: 'post',
    url: '/api/1.0/abstraction/query',
    baseURL: conn.url,
    headers: _requestHeader(conn),
    data: {
      pages: pages.map(e => { return { ..._.cloneDeep(e) } }),
      elements: elements.map(e => { return { ..._.cloneDeep(e) } }),
      conditions: conditions.map(c => { return { ..._.cloneDeep(c) } }),
      order: order.map(e => { return { ..._.cloneDeep(e), ...{ order: e.order || 'ASC' } } })
    },
    params: {
      folderAbstraction: conn.folder,
      abstraction: conn.abstraction
    }
  })
  const jPlakaProcess = await _tranformData(conn, response.data)
  return jPlakaProcess
}
const _loadTransformer = async (transformer = {}) => {
  let transformerLoaded = {}
  try {
    transformerLoaded = await load({ path: './plaka/transformers/', type: transformer.type })
  } catch (e) {
    let response = await httpClient({
      method: 'get',
      url: transformer.type
    })
    let script = response.data
    let fun = new Function(['services', 'transformer', 'jplaka'], script)
    transformerLoaded = {
      default ({ transformer = {}, jplaka }) {
        return fun.apply(this, [services, transformer, jplaka])
      }
    }
  }
  return transformerLoaded
}
const _executeTransformer = async (transformer = {}, _jplaka) => {
  const transformerLoaded = await _loadTransformer(transformer)
  if (!transformerLoaded.default || typeof transformerLoaded.default !== 'function') {
    console.warn(`Not defined 'default' function in ${transformer.type} transformer`)
  } else {
    _jplaka = await transformerLoaded.default({ transformer, jplaka: _jplaka })
  }
  return _jplaka
}
const _tranformData = async (connection = {}, data) => {
  if (!data) {
    throw new Error('Necessary data to instance jPlaka')
  }
  let _jplaka = _.cloneDeep(data)
  if (connection.transformer) {
    if (Array.isArray(connection.transformer)) {
      for (const transformer of connection.transformer) {
        _jplaka = await _executeTransformer(transformer, _jplaka)
      }
    } else {
      _jplaka = await _executeTransformer(connection.transformer, _jplaka)
    }
  }
  return new JPlakaProcess(_jplaka)
}
const _clearFilter = function (idElement, fields2notify = []) {
  let filters = _getHashFilters()
  delete filters[idElement]
  Store.commit(PLAKA.MUTATIONS.SET_FILTERS, {
    filters,
    fields2notify
  })
}
const _containsIdElement = (elements = [], filter = []) => elements.filter(e => filter.indexOf(e.idElement) !== -1).length > 0

const validateFields = (connection) => {
  return connection.id && connection.type && connection.url && connection.folder && connection.abstraction
}
const login = async function ({ username, password, token }) {
  const header = {}
  header[PLK_USER] = username
  header[PLK_PASSWORD] = password
  if (token) header[PLK_AUTH0] = token
  const response = await httpClient({
    method: 'get',
    url: '/api/1.0/users/token',
    baseURL: this.url,
    headers: header
  })
  return response.data.resource
}
const getStatistics = async function () {
  const response = await httpClient({
    method: 'get',
    url: '/api/1.0/abstraction/statistics',
    baseURL: this.url,
    headers: _requestHeader(this),
    params: {
      folderAbstraction: this.folder,
      abstraction: this.abstraction
    }
  })
  return response.data
}
const getDimensions = async function () {
  const response = await httpClient({
    method: 'get',
    url: '/api/1.0/abstraction/dimensions',
    baseURL: this.url,
    headers: _requestHeader(this),
    params: {
      folderAbstraction: this.folder,
      abstraction: this.abstraction
    }
  })
  return response.data
}
const getDimensionValues = async function (column = {}, { order = 'ASC' } = {}) {
  const response = await httpClient({
    method: 'get',
    url: '/api/1.0/abstraction/dimensions/values',
    baseURL: this.url,
    headers: _requestHeader(this),
    params: {
      folderAbstraction: this.folder,
      abstraction: this.abstraction,
      idElement: column.idElement,
      order
    }
  })
  return response.data
}
const getMeasures = async function () {
  const response = await httpClient({
    method: 'get',
    url: '/api/1.0/abstraction/measures',
    baseURL: this.url,
    headers: _requestHeader(this),
    params: {
      folderAbstraction: this.folder,
      abstraction: this.abstraction
    }
  })
  return response.data
}
const validateConnection = function () {
  return httpClient({
    method: 'get',
    url: '/api/1.0/isLogged',
    baseURL: this.url,
    headers: _requestHeader(this)
  })
}
const initData = function (elements = [], conditions = [], { onInit, onChange } = {}, { pages = [], filters, order = [] } = {}) {
  validateFields(this)
  _listeningObjects.push({ elements, conditions, onInit, onChange, pages, filters, order, conn: this })
  if (onInit) onInit()
  if (onChange) {
    const conditions2apply = conditions.filter(e => (e.values || []).length)
    let filtersList = getFilters(elements.map(e => e.idElement).concat(conditions.filter(e => !(e.values || []).length).map(c => c.idElement)))
    if (filters) {
      filtersList = filtersList.filter(f => filters.includes(f.idElement))
    }
    filtersList = filtersList.concat(conditions2apply)
    _getData(this, elements, filtersList, { pages, order }).then((data) => {
      onChange(data)
    })
  }
}
const stopData = function ({ onInit, onChange } = {}) {
  _listeningObjects = _listeningObjects.filter((item) => item.onInit !== onInit && item.onChange !== onChange)
}
const getDataByPage = function (elements = [], conditions = [], { pages = [], order } = {}) {
  return _getData(this, elements, conditions, { pages, order })
}
const getFilters = function (filter = []) {
  return _getListFilters(filter)
}
const _evalFilterToApply = (appliedFilters, filter) => {
  if (!appliedFilters[filter.idElement]) {
    appliedFilters[filter.idElement] = [filter]
  } else {
    const positionByCondition = appliedFilters[filter.idElement].findIndex(e => e.condition === filter.condition)
    if (positionByCondition === -1) {
      appliedFilters[filter.idElement].push(filter)
    } else {
      appliedFilters[filter.idElement][positionByCondition].values = [...new Set((appliedFilters[filter.idElement][positionByCondition].values || []).concat(filter.values))]
    }
  }
}
const _filterRelations = async function (filters) {
  const filterMapped = await filterServices.getRelations()
  if (!Object.keys(filterMapped).length) return filters
  const filterValue = _.cloneDeep(filters)
  const _filtersWithElements = {}
  filters.forEach(filter => {
    if (!_filtersWithElements[filter.idElement]) _filtersWithElements[filter.idElement] = []
    _filtersWithElements[filter.idElement].push(filter)
  })
  Object.keys(_filtersWithElements).forEach(idFilter2Apply => {
    if (filterMapped[idFilter2Apply]) {
      filterMapped[idFilter2Apply].forEach(newAssignation => {
        if (newAssignation.idElement) {
          // Si este nuevo elemento tiene que llamar a otros elementos
          (_filtersWithElements[idFilter2Apply] || []).forEach(filter2extend => {
            filterValue.push({ ...filter2extend, ...newAssignation })
          })
        }
      })
    }
  })
  return filterValue
}
const applyFilters = async function (filters = [], { overwrite = true } = {}) {
  const _filters = await _filterRelations(filters)
  let failValidation = false
  _filters.forEach(filter => {
    if ((filter.type !== ELEMENT_TYPES.DIMENSION && filter.type !== ELEMENT_TYPES.VIRTUAL_CONDITION) || !filter.idElement || !filter.values) {
      services.logger('ServicePlaka', `applyFilter:bad format in filter:${JSON.stringify(filter)}`, LOG_SUCCESS)
      failValidation = true
    } else if (overwrite) {
      _clearFilter(filter.idElement, [])
    }
  })
  if (!failValidation) {
    const appliedFilters = _getHashFilters()
    _filters.forEach(filter => {
      _evalFilterToApply(appliedFilters, filter)
    })
    Store.commit(PLAKA.MUTATIONS.SET_FILTERS, {
      filters: appliedFilters,
      fields2notify: _filters
    })
  }
}
const applyFilter = function (filter, { overwrite = true } = {}) {
  applyFilters([filter], { overwrite })
}
const clearFilter = async function (filter) {
  const relations = await _filterRelations([filter])
  relations.forEach(item => _clearFilter(item.idElement, [item]))
}
const clearAll = function () {
  Store.commit(PLAKA.MUTATIONS.REMOVE_FILTERS, {
    fields2notify: _getListFilters([])
  })
}
const evaluate = function (fields2notify) {
  const listFields = (fields2notify || getFilters([])).map(f => f.idElement)
  _listeningObjects.forEach(l => {
    if (_containsIdElement(l.elements, listFields) || _containsIdElement(l.conditions, listFields) || _containsIdElement(l.pages, listFields)) {
      const conditions2apply = l.conditions.filter(e => (e.values || []).length)
      let filters = getFilters(l.elements.map(e => e.idElement).concat(l.conditions.filter(e => !(e.values || []).length).map(c => c.idElement)))
      if (l.filters) {
        filters = filters.filter(f => l.filters.includes(f.idElement))
      }
      filters = filters.concat(conditions2apply)
      // if (l.onInit) l.onInit() No ejecuta el onInit ya que debe tener implementado el onChange
      if (l.onChange) {
        _getData(l.conn, l.elements, filters, { pages: l.pages, filters: l.filters, order: l.order }).then((data) => {
          l.onChange(data)
        })
      }
    }
  })
}
const loginInApp = function ({ username, password, token }) {
  return Store.dispatch({
    type: USER.ACTIONS.LOGIN,
    connection: this,
    username,
    password,
    token
  })
}

export default {
  DIMENSION_ROLES,
  ELEMENT_TYPES,
  validateFields,
  loginInApp,
  login,
  getStatistics,
  getDimensions,
  getMeasures,
  getDimensionValues,
  initData,
  stopData,
  getDataByPage,
  getFilters,
  applyFilters,
  applyFilter,
  clearFilter,
  clearAll,
  evaluate,
  validateConnection
}
