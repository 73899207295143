import { addNamespaceInOperations } from '../helper'

import Vue from 'vue'

export const MUTATIONS_METHODS = {
  SET_USER: 'setUser',
  REMOVE_USER: 'removeUser'
}

export const mutations = {
  [MUTATIONS_METHODS.SET_USER] (state, { host, user }) {
    Vue.set(state, 'host', host)
    Vue.set(state, 'user', user)
  },
  [MUTATIONS_METHODS.REMOVE_USER] (state) {
    Vue.set(state, 'user', {})
  }
}

export const MUTATIONS = addNamespaceInOperations('cms', MUTATIONS_METHODS)
