import { DASHBOARD_URL_CONFIG_FILE } from '@/enviroment/app'
import loggerService from './logger'
import componentService from './component'
import translationService from './translation'
import connectionService from './connection'
import filtersService from './filters'
import cmsService from './cms'
import eventService from './events'
export { LOG_SUCCESS, LOG_ERROR } from './logger'
export const PATH_URL = DASHBOARD_URL_CONFIG_FILE

export const getUrlParam = (param) => {
  let params = decodeURIComponent(window.location.href).split('?')
  let regExpression = new RegExp(`${param}=([^&$]*)`, 'gi')
  let regExpressionExec = params.length === 2 ? regExpression.exec(params[1]) : undefined
  return regExpressionExec && regExpressionExec.length === 2 ? regExpressionExec[1] : undefined
}
const purgeCache = () => {
  connectionService.purgeCache()
  filtersService.purgeCache()
  componentService.purgeCache()
}

export default {
  purgeCache,
  component: componentService,
  logger: loggerService,
  translation: translationService,
  connection: connectionService,
  event: eventService,
  cms: cmsService,
  filters: filtersService
}
