import Vue from 'vue'
import { getHashKeyValue } from '@/plugins/object'
import { v4 as uuidv4 } from 'uuid'
export default {
  props: {
    default: {
      required: true
    },
    global: {
      type: Object,
      required: true
    },
    selectedCube: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      id: `id_${uuidv4()}`,
      model: undefined
    }
  },
  methods: {
    setModel () {
      Vue.set(this, 'model', this.default)
    },
    getGlobalParam (key) {
      return getHashKeyValue(this.global)[key]
    }
  },
  watch: {
    default: {
      deep: true,
      handler () {
        this.setModel()
      }
    },
    model: {
      deep: true,
      handler () {
        if (typeof this.model === 'boolean') {
          this.$emit('changeValue', { value: this.model })
        } else {
          this.$emit('changeValue', { value: Number(this.model) || this.model })
        }
      }
    }
  }
}
