export const installFactory = ({ components, directives, plugins } = {}) => {
  const install = (Vue, config = {}) => {
    if (install.installed) {
      return
    }
    install.installed = true
    registerComponents(Vue, components, config)
    registerDirectives(Vue, directives, config)
    registerPlugins(Vue, plugins)
  }

  install.installed = false

  return install
}

export const pluginFactory = (opts = {}, extend = {}) => {
  return {
    ...extend,
    install: installFactory(opts)
  }
}

export const registerPlugins = (Vue, plugins = {}) => {
  for (let plugin in plugins) {
    if (plugin && plugins[plugin]) {
      Vue.use(plugins[plugin])
    }
  }
}

export const registerComponent = (Vue, name, def) => {
  if (Vue && name && def) {
    Vue.component(name, def)
  }
}

export const registerComponents = (Vue, components = {}, config = {}) => {
  for (let component in components) {
    let componentName = components[component].name || component
    componentName = config.prefix ? config.prefix + componentName : componentName
    registerComponent(Vue, componentName, components[component])
  }
}

export const registerDirective = (Vue, name, def) => {
  if (Vue && name && def) {
    // Ensure that any leading V is removed from the
    // name, as Vue adds it automatically
    Vue.directive(name, def)
  }
}

export const registerDirectives = (Vue, directives = {}, config = {}) => {
  for (let directive in directives) {
    let directiveName = directives[directive].name || directive
    directiveName = config.prefix ? config.prefix + directiveName : directiveName
    registerDirective(Vue, directiveName, directives[directive])
  }
}
