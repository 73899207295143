import { pluginFactory } from '@/plugins/plugin'
import Selector, { TYPE } from './Selector.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const SelectorPlugin = pluginFactory({
  components: { Selector }
})

export { SelectorPlugin, Selector, TYPE }
export default Selector
