import { pluginFactory } from '@/plugins/plugin'
import DateFilter, { TYPE } from './DateFilter.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const DateFilterPlugin = pluginFactory({
  components: { DateFilter }
})

export { DateFilterPlugin, DateFilter, TYPE }
export default DateFilter
