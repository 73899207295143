import { pluginFactory } from '@/plugins/plugin'
import Elements, { TYPE } from './Elements.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const ElementsPlugin = pluginFactory({
  components: { Elements }
})

export { ElementsPlugin, Elements, TYPE }
export default Elements
