import { pluginFactory } from '@/plugins/plugin'
import { BasicTablePlugin } from './BasicTable'
import { BasicKPIPlugin } from './BasicKPI'
import { AutoRefreshPlugin } from './AutoRefresh'
import { BasicHtmlPlugin } from './BasicHtml'
import { BasicFilterPlugin } from './BasicFilter'
import { BasicChartPlugin } from './BasicChart'
import { BasicContainerPlugin } from './BasicContainer'
import { DateFilterPlugin } from './DateFilter'

export const BasicPlugins = pluginFactory({
  plugins: {
    BasicTablePlugin,
    BasicKPIPlugin,
    AutoRefreshPlugin,
    BasicHtmlPlugin,
    BasicFilterPlugin,
    BasicChartPlugin,
    BasicContainerPlugin,
    DateFilterPlugin
  }
})

export { BasicTable } from './BasicTable'
export { BasicKPI } from './BasicKPI'
export { AutoRefresh } from './AutoRefresh'
export { BasicHtml } from './BasicHtml'
export { BasicFilter } from './BasicFilter'
export { BasicChart } from './BasicChart'
export { BasicContainer } from './BasicContainer'
export { DateFilter } from './DateFilter'
