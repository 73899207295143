import Vue from 'vue'
import { LOG_SUCCESS } from '@/services/logger'

export const PLAKA_MIXIN = {
  props: {
    connection: {
      type: String,
      required: false
    },
    maximized: {
      type: Boolean,
      required: false,
      default: false
    },
    plkparams: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      plkConnection$: undefined
    }
  },
  mounted () {
    this._initComponent()
  },
  computed: {
    path () {
      return this.$route ? this.$route.path : 'index'
    },
    connectionStarted () {
      return this.plkConnection$ !== undefined
    }
  },
  methods: {
    _initComponent () {
      this.$servicesPLK.logger.log(this.path, `${this.$options.name}:initComponent`, LOG_SUCCESS)
      this.$servicesPLK.event.onEvent(this.$servicesPLK.event.BASIC_ACTIONS.DESTROY, this._plakaDestroy)
      this.onInitComponent()
      this._initConnection()
    },
    _plakaDestroy () {
      this.$servicesPLK.logger.log(this.path, `${this.$options.name}:plakaDestroy`, LOG_SUCCESS)
      this.onDestroyComponent()
      this.$servicesPLK.event.offEvent(this.$servicesPLK.event.BASIC_ACTIONS.DESTROY, this._plakaDestroy)
    },
    _initConnection () {
      if (this.connection) {
        this.$servicesPLK.connection.getConnection(this.connection).then((connection) => {
          Vue.set(this, 'plkConnection$', connection)
        })
      }
    },
    onInitComponent () {},
    onDestroyComponent () {},
    getConnection () {
      if (!this.plkConnection$) {
        console.warn(`The ${this.connection} is not started yet`)
        return {}
      }
      return this.plkConnection$
    },
    getPackage () {
      return {}
    },
    getReadme () {
      return `# ${this.$options.name} #`
    }
  },
  watch: {
    plkparams (newValue) {
      this._initComponent()
    }
  }
}
