import { APP_DEFAULT_PYODIDE_FILES, APP_DEFAULT_PYODIDE_INDEX } from '@/enviroment/app'
import httpClient from '../../../../http'
import services from '@/services'

const _getName = ({ index } = {}) => (index || APP_DEFAULT_PYODIDE_INDEX).replace(/[^0-9a-z-A-Z ]/g, '')
const _injectJavascript = (url) => {
  if (!url) {
    return Promise.reject(new Error('Not defined url'))
  }
  const name = _getName({ index: url })
  if (window[name]) return window[name]

  window[name] = new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.addEventListener('load', () => {
      resolve()
    })
    script.addEventListener('error', (e) => {
      reject(new Error(`Error loading ${url}`))
    })
    script.src = url
    document.head.appendChild(script)
  })
  return window[name]
}
const _pyodidePath = ({ index } = {}) => index || APP_DEFAULT_PYODIDE_INDEX
const _pyodideFiles = ({ files } = {}) => files || APP_DEFAULT_PYODIDE_FILES
const _instancePyodide = async (pyodide) => {
  if (!window.languagePluginUrl) {
    await _injectJavascript(_pyodidePath(pyodide))
    try {
      await window.loadPyodide({ indexURL: _pyodideFiles(pyodide) })
    } catch (e) {
      await new Promise((resolve) => {
        // No hay un método para esperar mientras carga el pyodide por lo que lo hacemos con un timeout
        const validateIsLoadPyodide = () => {
          if (Object.keys(window.pyodide).length === 0) {
            setTimeout(() => validateIsLoadPyodide(), 500)
          } else {
            resolve()
          }
        }
        return validateIsLoadPyodide()
      })
    }
  }
}
const _loadFile = async (file) => {
  _loadFile.cache = _loadFile.cache || {}
  if (!file) {
    throw new Error('Not defined file path in python transformer')
  }
  if (!_loadFile.cache[file]) {
    const { data } = await httpClient({
      method: 'get',
      url: file
    })
    _loadFile.cache[file] = data
  }
  return _loadFile.cache[file]
}
const _loadFiles = async ({ files = [] } = {}) => {
  if (!Array.isArray(files) || !files.length) {
    throw new Error('Necessary array files in python transformer')
  }
  const filesLoaded = await Promise.all(files.map(file => _loadFile(file)))
  return filesLoaded
}
const _conversor = (proxy) => {
  if (proxy.toJs === undefined) {
    return proxy
  }
  const _proxy = proxy.toJs(1)
  if (Array.isArray(_proxy)) {
    return _proxy.map(e => _conversor(e))
  }
  const map = {}
  _proxy.forEach((value, key) => {
    map[key] = _conversor(value)
  })
  return map
}
export default async ({ transformer = {}, jplaka }) => {
  try {
    if (!jplaka) {
      throw new Error('Error execute: no data found')
    }
    const promises = []
    promises.push(_instancePyodide(transformer.pyodide))
    promises.push(_loadFiles(transformer))
    const results = await Promise.all(promises)
    window.pyodide.globals.set('jplaka', window.pyodide.toPy(jplaka))
    await window.pyodide.loadPackage(transformer.packages || [])
    // No podemos usar runPythonAsync por que se mezclan los scopes
    results[1].forEach(file => {
      window.pyodide.runPython(file)
    })
    return _conversor(window.pyodide.globals.get('jplaka'))
  } catch (e) {
    services.event.emitEvent(services.event.BASIC_ACTIONS.ERROR, { type: 'error', msg: e.toString() })
  }
}
