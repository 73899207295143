import { pluginFactory } from '@/plugins/plugin'
import Conditions, { TYPE } from './Conditions.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const ConditionsPlugin = pluginFactory({
  components: { Conditions }
})

export { ConditionsPlugin, Conditions, TYPE }
export default Conditions
