import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import plaka from '@/store/modules/plaka'
import design from '@/store/modules/design'
import user from '@/store/modules/user'
import cms from '@/store/modules/cms'
import createLogger from '@/plugins/logger'
import { IS_DEBUG_MODE } from '@/enviroment/global'
const PERSISTANCE_STORE_KEY = 'plakastore'

Vue.use(Vuex)

export { PLAKA } from '@/store/modules/plaka'
export { USER } from '@/store/modules/user'
export { CMS } from '@/store/modules/cms'
export { DESIGN } from '@/store/modules/design'
export const Store = new Vuex.Store({
  strict: IS_DEBUG_MODE,
  modules: {
    plaka,
    user,
    cms,
    design
  },
  plugins: [
    createPersistedState({
      key: PERSISTANCE_STORE_KEY,
      paths: ['design.files', 'design.views', 'design.javascript', 'design.externalComponents', 'design.colors', 'user', 'cms', 'plaka']
    }),
    createLogger()
  ]
})
