import { pluginFactory } from '@/plugins/plugin'
import BasicKPI, { TYPE } from './BasicKPI.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const BasicKPIPlugin = pluginFactory({
  components: { BasicKPI }
})

export { BasicKPIPlugin, BasicKPI, TYPE }
export default BasicKPI
