<template>
    <div>
      <select :id="id" v-model="model" :disabled="plkparams.disabled" :required="plkparams.required" class="u-full-width" :placeholder="$t(plkparams.placeholder)">
        <option v-for="(connection, index) in connections" :key="index" :value="connection.id">
          {{connection.id}}
        </option>
      </select>
    </div>
</template>

<script>
import Vue from 'vue'
import fields from '@/mixins/viewer/types/fields'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  mixins: [
    fields
  ],
  data () {
    return {
      connectionList: []
    }
  },
  computed: {
    connections () {
      if (this.plkparams.connectionType) {
        return this.connectionList || []
      } else {
        return (this.connectionList || []).filter(e => e.type === this.plkparams.connectionType)
      }
    }
  },
  methods: {
    onInitComponent () {
      this.setModel()
      this.getConnections()
    },
    getConnections () {
      this.$servicesPLK.connection.getConnections().then((connections) => {
        Vue.set(this, 'connectionList', connections)
        if (!this.model && connections.length) {
          Vue.set(this, 'model', connections[0].id)
        }
      })
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style scoped lang="scss">
</style>
