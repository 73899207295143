<template>
    <div class="component" :class="[`type_${normalizeType(plkparams.type)} ${plkparams.parentClass || ''}`, { 'maximize': maximize }]" @mouseleave="showMenu=false">
        <div class="menu" :title="$t('viewer.menu.open')" v-if="haveMenu && !maximize" @click="showMenu=true" >
          <i v-if="!showMenu" class="btn fas fa-ellipsis-v"></i>
          <div class="wrapper">
            <transition name="slide">
              <div v-if="showMenu" class="items">
                <i @click.stop="showMenu=false" class="fas fa-times" :title="$t('viewer.menu.close')"></i>
                <i @click.stop="maximizeNow()" class="fas fa-expand-alt" :title="$t('viewer.menu.expand')"></i>
                <i v-for="(action, index) in menuActions" :key="index" :class="action.icon" @click.stop="executeAction(action.function)" :title="$t(action.title || '')"/>
              </div>
            </transition>
          </div>
        </div>
        <component v-bind:is="instance" :connection="plkparams.connection" :plkparams="plkparams.plkparams" :maximized="maximize"></component>
    </div>
</template>
<script>
import Vue from 'vue'
import i18n from './i18n'
export default {
  name: 'ObjectLoader',
  props: {
    instance: {
      required: true
    }
  },
  data () {
    return {
      showMenu: false,
      maximize: false
    }
  },
  computed: {
    haveMenu () {
      return (this.plkparams || {}).menu === undefined || Array.isArray((this.plkparams || {}).menu) ? true : this.plkparams.menu
    },
    menuActions () {
      return (this.plkparams || {}).menu && Array.isArray((this.plkparams || {}).menu) ? this.plkparams.menu : []
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.startListenerExitFullScreen()
    },
    startListenerExitFullScreen () {
      document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {
          Vue.set(this, 'maximize', true)
        } else {
          Vue.set(this, 'maximize', false)
        }
      })
    },
    executeAction (exec) {
      if (exec) {
        try {
          let fun = new Function(['ctx'], exec)
          return fun.apply(this, [this])
        } catch (e) {
          return exec
        }
      }
    },
    normalizeType (type) {
      return this.$servicesPLK.component.normalizeType(type)
    },
    maximizeNow () {
      this.requestFullscreen(this.$el)
    },
    async requestFullscreen (element) {
      try {
        if (!element) {
          console.error('Element is not defined')
        } else if (element.requestFullscreen) {
          await element.requestFullscreen()
        } else if (element.webkitRequestFullscreen) {
          await element.webkitRequestFullscreen()
        } else if (element.mozRequestFullScreen) {
          await element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          await element.msRequestFullscreen()
        } else {
          alert(this.$t('designer.objectLoader.notsupport.browser'))
        }
        Vue.set(this, 'maximize', true)
      } catch (e) {
        alert(this.$t('designer.objectLoader.notsupport.embebedmode'))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .component {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: 0;
    &.maximize {
      background: white;
      width: 100vw;
      height: 100vh;
      overflow: scroll;
      margin: 0px !important;
    }
    &:hover {
      .menu {
        display: block;
      }
    }
  }
  .menu {
    position: absolute;
    display: none;
    .btn {
      padding-top: .5rem;
      padding-left: 1rem;
      cursor: pointer;
    }
    .wrapper {
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .items {
      background-color: white;
      border: solid #efefef 1px;
      border-bottom: solid #efefef 1px;
      width: 25px;
      padding: 0 10px;
      border-radius: 0 0 20px 0px;
      i {
        margin: 13px 5px 14px;
        color: black;
        font-size: 1em;
        cursor: pointer;
      }
    }
  }
  .slide-enter-active {
    transition: all .6s ease;
  }
  .slide-leave-active {
    transition: all .6s ease;
  }
  .slide-enter {
    transform: translateY(-100%);
    opacity: 0;
  }
  .slide-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }
  @media (hover: none) and (pointer: coarse) {
    .menu {
      display: block;
    }
  }
</style>
