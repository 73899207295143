import _ from 'lodash'
export const addNamespaceInOperations = (module = '', operations = {}) => {
  if (module.length === 0) {
    console.warn('Empty namespace')
  }
  let oper2update = _.cloneDeep(operations)
  Object.keys(oper2update).forEach(key => {
    oper2update[key] = `${module}/${oper2update[key]}`
  })
  return oper2update
}
