<template>
    <div class="select_element" v-if="model">
      <div class="options" v-if="!haveModel">
        <input :placeholder="$t('configuration.types.simpleelement.search')" class="w-100" type="text" :value="search" @input="e => search = e.target.value" @focus="searching = true" @blur="searching = false"/>
        <div v-if="search" @mouseover="searching = true" @mouseleave="searching = false" class="items">
          <div @click="addElement(element)" class="item selectable" v-for="(element, index) in element" :key="index" :class="{ 'disabled': selectedCube && selectedCube !== element.cube }" :title="selectedCube && selectedCube !== element.cube ? $t('configuration.types.simpleelement.notposiblejoindata') : $t('configuration.types.simpleelement.click2add')">
            <div class="elementId">
              <span class="suptitle">{{element.cube}}</span>
              <span class="label">{{element.label}}</span>
            </div>
            <i class="fas fa-plus pull-right add pointer"></i>
          </div>
          <div class="item text-center selectable" v-if="!element.length">
            {{$t('configuration.types.simpleelement.notelement')}}
          </div>
        </div>
      </div>
      <div class="item draggable" v-if="haveModel">
        <div class="elementId">
          <span class="suptitle">{{selectedCube}}</span>
          <span class="label">{{model.label}}</span>
        </div>
        <i @click="removeElement()" class="fas fa-times remove pointer"></i>
      </div>
      <div class="item text-center selectable" v-if="!connectionObject">
        {{$t('configuration.types.simpleelement.notselectedConnection')}}
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import fields from '@/mixins/viewer/types/fields'
import { ELEMENT_TYPES } from '@/services/connection/plaka/JPlakaProcess'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  mixins: [
    fields
  ],
  data () {
    return {
      searching: false,
      search: '',
      connectionId: undefined,
      connectionObject: undefined,
      columns: [],
      measures: []
    }
  },
  computed: {
    element () {
      const search = (this.search || '').toUpperCase()
      const elements = (this.columns || []).concat(this.measures || [])
      if (search === '*') return elements.filter(e => this.model.label !== e.label)
      return elements.filter(e => e.label.toUpperCase().indexOf(search) !== -1 && this.model.label !== e.label)
    },
    haveModel () {
      return this.model !== undefined && Object.keys(this.model).length > 0
    }
  },
  methods: {
    onInitComponent () {
      this.loadConnectionName()
      this.setModel()
    },
    removeElement () {
      Vue.set(this, 'model', {})
      this.$emit('unselectCube')
    },
    addElement ({ idElement, label, type, cube }) {
      if (idElement && type) {
        Vue.set(this, 'model', { idElement, label, type })
        Vue.set(this, 'search', '')
        this.$emit('selectCube', { cube })
      }
    },
    loadConnectionName () {
      const globalParam = this.getGlobalParam(this.plkparams.connection)
      Vue.set(this, 'connectionId', globalParam === undefined ? this.plkparams.connection : globalParam)
    },
    async getColumns () {
      Vue.set(this, 'columns', [])
      if ((!this.plkparams.element || this.plkparams.element === ELEMENT_TYPES.DIMENSION) && this.connectionObject) {
        const _columns = await this.connectionObject.getDimensions()
        Vue.set(this, 'columns', _columns)
      }
    },
    async getMeasures () {
      Vue.set(this, 'measures', [])
      if ((!this.plkparams.element || this.plkparams.element === ELEMENT_TYPES.MEASURE) && this.connectionObject) {
        const _measures = await this.connectionObject.getMeasures()
        Vue.set(this, 'measures', _measures)
      }
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  },
  watch: {
    global () {
      this.loadConnectionName()
    },
    async connectionId () {
      if (this.connectionId) {
        const _connectionApi = await this.$servicesPLK.connection.getConnection(this.connectionId)
        Vue.set(this, 'connectionObject', _connectionApi)
      }
    },
    connectionObject (newValue, oldValue) {
      if (oldValue && newValue.id !== oldValue.id) {
        Vue.set(this, 'model', {})
      }
      this.getColumns()
      this.getMeasures()
    }
  }
}
</script>

<style scoped lang="scss">
  .select_element {
    .remove {
      &:hover {
        color: #900c3e;
      }
    }
    .add {
      display: none;
      color: green;
    }
    .fas {
      line-height: 33px;
    }
    .item {
      border: solid 1px #efefef;
      border-radius: 4px;
      padding: 2px 10px;
      display: flex;
      height: fit-content;
      justify-content: flex-start;
      gap: 3rem;

      &.selectable {
        cursor: pointer;
        border: 0;
        font-weight: 600;
        &:hover {
          background: #efefef;
        }
        &.disabled{
          cursor: no-drop;
          color: #cecece;
          &:hover {
            background: none;
          }
        }
        &:hover {
          .add {
            margin-left: auto;
            display: block;
            line-height: 33px;
            line-height: 33px;
          }
        }
      }
      .elementId {
        display: flex;
        flex-direction: column;
        max-width: 40%;
        min-width: 15%;
        .suptitle {
          font-size: .9rem;
          font-weight: lighter;
        }
        .label {
          margin-top: -5px;
        }
      }

      .remove {
        margin-left: auto;
      }
    }
  }
</style>
