import Vue from 'vue'
import _ from 'lodash'
import httpClient from './http'
import { Store } from '@/store'
import vuexI18n from 'vuex-i18n'
import services from '@/services'
import { getListFields } from '@/plugins/object'
import { APP_I18N_FILE, APP_DEFAULT_LANG, APP_DEFAULT_LANGUAGES } from '@/enviroment/app'
const I18N_MODULE_NAME = 'i18n'

const _initWithTranslations = (translations, overwrite = true) => {
  Object.keys(translations).forEach((key) => {
    const _translations = overwrite ? translations[key] : _getNotInserted(key, translations[key])
    if (_needRefesh(_translations)) {
      Vue.i18n.add(key, _translations)
    }
  })
}
const _getNotInserted = (locale, translations2evaluate = {}) => {
  let vue18nTranslations = Store.state[I18N_MODULE_NAME].translations || {}
  let translations = _.cloneDeep(translations2evaluate)

  getListFields(translations2evaluate).forEach(field => {
    if (vue18nTranslations[locale] && vue18nTranslations[locale][field]) {
      eval(`delete translations['${field.replace(/\./gi, '\'][\'')}']`)
    }
  })
  return translations
}
const _needRefesh = (translations) => {
  return Object.keys(_cleanObject(translations) || {}).length > 0
}
const _cleanObject = (obj) => {
  if ((obj !== null && obj !== undefined) && typeof obj === 'object' && Object.keys(obj).length) {
    Object.keys(Object.assign(obj)).forEach(key => {
      obj[key] = _cleanObject(obj[key])
      if (obj[key] === undefined || (typeof obj === 'object' && !Object.keys(obj[key]).length)) {
        delete obj[key]
      }
    })
    return obj
  } else if (typeof obj !== 'object' && obj.length) {
    return obj
  } else {
    return undefined
  }
}
const _defaultInit = async () => {
  try {
    const config = await services.translation.getTranslationConfig()
    loadLang({ languages: config.data.languages || APP_DEFAULT_LANGUAGES })
    Vue.i18n.set(config.data.languages.indexOf(langBrowser) === -1 ? (config.data.defaultLang || APP_DEFAULT_LANG) : langBrowser)
  } catch (e) {
    console.warn(`Using default config lang: ${e.message}`)
    loadLang({ languages: APP_DEFAULT_LANGUAGES })
    Vue.i18n.set(langBrowser)
  }
}

const init = (translations, { overwrite = true } = {}) => {
  if (!Vue.i18n) {
    Vue.use(vuexI18n.plugin, Store, {
      warnings: false
    })
  }

  if (!translations) {
    _defaultInit()
  } else {
    _initWithTranslations(translations, overwrite)
  }
}
const langBrowser = navigator.language
const loadLang = ({ path, languages }) => {
  let promises = []
  for (let lang of languages) {
    promises.push(services.translation.getTranslation({ path, lang }).then(translation => {
      Vue.i18n.add(translation.lang, translation.data)
      return Promise.resolve()
    }))
  }
  return Promise.all(promises)
}
const getTranslationConfig = async ({ path } = {}) => {
  return httpClient({
    method: 'get',
    url: (path || APP_I18N_FILE).replace(/@lang/gi, 'config')
  }).then((response) => {
    return { data: response.data }
  }).catch(e => {
    return Promise.reject(new Error(`Not found language config`))
  })
}
const getTranslation = async ({ path, lang } = {}) => {
  return httpClient({
    method: 'get',
    url: (path || APP_I18N_FILE).replace(/@lang/gi, lang)
  }).then((response) => {
    return { lang, data: response.data }
  }).catch(e => {
    return Promise.reject(new Error(`Not found translation to lang: '${lang}'`))
  })
}

export default {
  init,
  loadLang,
  langBrowser,
  getTranslationConfig,
  getTranslation
}
