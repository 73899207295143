export const BASIC_ACTIONS = {
  LIBRARY_LOADED: 'plakaObjectLoaded',
  DESTROY: 'plakaDestroy',
  ERROR: 'plakaError',
  NETWORK_ERROR: 'plakaNetworkError',
  START_LOADING_BAR: 'plakaStartLoadingBar',
  STOP_LOADING_BAR: 'plakaStopLoadingBar',
  ERROR_LOADING_BAR: 'plakaErrorLoadingBar'
}

export default BASIC_ACTIONS
