export const APP_NAME = process.env.VUE_APP_NAME || ''
export const APP_VERSION = process.env.VUE_APP_VERSION || ''
export const IS_DESIGN_MODE = process.env.VUE_APP_IS_DESIGN_MODE === 'true'
export const IS_EXTERNAL_MODE = process.env.VUE_APP_IS_EXTERNAL_MODE === 'true'
export const APP_DEFAULT_LANG = process.env.VUE_APP_DEFAULT_LANG || ''
export const DASHBOARD_URL_CONFIG_FILE = process.env.VUE_APP_DASHBOARD_URL_CONFIG_FILE || ''
export const APP_VIEWS_CONFIG_PATH = process.env.VUE_APP_VIEWS_CONFIG_PATH || ''
export const APP_EXPORT_ZIP = process.env.VUE_APP_EXPORT_ZIP || 'base.zip'
export const APP_FOLDER_PATH = process.env.VUE_APP_FOLDER_PATH || 'base/'

export const APP_CONNECTION_CONFIG_FILE = process.env.VUE_APP_CONNECTION_CONFIG_FILE || 'connections.js'
export const APP_FILTER_RELATIONS_CONFIG_FILE = process.env.VUE_APP_FILTER_RELATIONS_CONFIG_FILE || 'filters_relations.json'
export const APP_STYLES_CONFIG_FILE = process.env.VUE_APP_STYLES_CONFIG_FILE || 'styles/style.css'
export const APP_COLORS_CONFIG_FILE = process.env.VUE_APP_COLORS_CONFIG_FILE || 'styles/colors.css'
export const APP_JAVASCRIPT_CONFIG_FILE = process.env.VUE_APP_JAVASCRIPT_CONFIG_FILE || 'index.js'
export const APP_I18N_CONFIG_FILE = process.env.VUE_APP_APP_I18N_CONFIG_FILE || 'i18n/config.json'
export const APP_I18N_ES_FILE = process.env.VUE_APP_I18N_ES_FILE || 'i18n/es.json'
export const APP_I18N_EN_FILE = process.env.VUE_APP_I18N_EN_FILE || 'i18n/en.json'

export const APP_URL_FILENAME_PARAM = process.env.VUE_APP_URL_FILENAME_PARAM || 'file'

export const APP_FILES_TO_DISCARD_ON_LOAD = process.env.VUE_APP_FILES_TO_DISCARD_ON_LOAD || ''

export const APP_DEFAULT_LANGUAGES = process.env.VUE_APP_DEFAULT_LANGUAGES || []
export const APP_I18N_FILE = process.env.VUE_APP_I18N_FILE || ''
export const APP_EXTERNAL_COMPONENT_FILE = process.env.VUE_APP_EXTERNAL_COMPONENT_FILE || ''
export const APP_TIME_BASE_TO_STOP_LOADING = process.env.VUE_APP_TIME_BASE_TO_STOP_LOADING || ''
export const APP_LOADING_IMAGE = process.env.VUE_APP_LOADING_IMAGE || ''
export const APP_ERROR_IMAGE = process.env.VUE_APP_ERROR_IMAGE || ''
export const APP_VALIDATING_UPDATING_OBJECT = process.env.VUE_APP_VALIDATING_UPDATING_OBJECT || true
export const APP_LICENSE_FILE = process.env.VUE_APP_LICENSE_FILE || ''
export const APP_NOTIFICATION_TIME = process.env.VUE_APP_NOTIFICATION_TIME || 10000
export const APP_REDIRECT_ON_LOGIN = process.env.VUE_APP_REDIRECT_ON_LOGIN || 'index'
export const APP_PAGENAME_REDIRECT_ON_LOGIN = process.env.VUE_APP_PAGENAME_REDIRECT_ON_LOGIN || 'index'
export const APP_PRINCIPAL_COLOR = process.env.VUE_APP_PRINCIPAL_COLOR || '#F4DCC5'

export const APP_DEFAULT_PYODIDE_FILES = process.env.VUE_APP_DEFAULT_PYODIDE || 'https://cdn.jsdelivr.net/pyodide/v0.17.0/full/'
export const APP_DEFAULT_PYODIDE_INDEX = process.env.VUE_APP_DEFAULT_PYODIDE || 'https://cdn.jsdelivr.net/pyodide/v0.17.0/full/pyodide.js'

export const LOGOUT_PATH = process.env.VUE_APP_LOGOUT_PATH || 'logout'
export const LOGIN_PATH = process.env.VUE_APP_LOGOUT_PATH || 'login'
export const ABOUT_PATH = process.env.VUE_APP_ABOUT_PATH || 'about'
