const validateFields = (connection) => {
  return connection.id && connection.type && connection.url
}

const audit = function ({ page, operation, type }) {
  console.info('%c audit', 'color: #9E9E9E; font-weight: bold', {
    page,
    operation,
    type
  })
}

export default {
  validateFields,
  audit
}
