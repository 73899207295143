import base, { init as initBase } from './base'
import xhook from 'xhook'
import { Store, DESIGN } from '@/store'
export const getContent = (url = '') => {
  const partsOfUrl = url.split(location.host)
  const filename = partsOfUrl[partsOfUrl.length - 1]
  const content = Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](filename.replace(new RegExp('^/', 'gi'), ''))
  return content
}

export const init = () => {
  initBase()
  xhook.before((request, response) => {
    const content = getContent(request.url)
    if (content) {
      response({ status: 200, statusText: 'OK', headers: { 'content-length': content.length, _overwritedata: true } })
    } else {
      response()
    }
  })
  base.interceptors.response.use((response) => {
    if (response.headers._overwritedata) {
      const content = getContent(response.config.url)
      try {
        response.data = content.isJSON ? JSON.parse(content.data) : content.data
      } catch (e) {
        response.data = content.data
      }
    }
    return response
  })
}

export default base
