import { pluginFactory } from '@/plugins/plugin'
import BasicFilter, { TYPE } from './BasicFilter.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const BasicFilterPlugin = pluginFactory({
  components: { BasicFilter }
})

export { BasicFilterPlugin, BasicFilter, TYPE }
export default BasicFilter
