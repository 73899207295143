<template>
  <div class="test-connection">
    <h1>{{$t('example')}}</h1>
    {{plkConnection$}}

  </div>
</template>

<script>
import services from '@/services'
import configuration from './package.json'
import readme from './README.md'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  methods: {
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style scoped lang="scss">
</style>
