<template>
  <div :id="editorId" class="yimo-vue-editor"></div>
</template>
<script>
import assign from 'object-assign'
import E from './assets/js/wangEditor.js'
export default {
  name: 'yimo-vue-editor',
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: null
    },
    uploadHandler: {
      type: Function
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      editorId: `editor_${Date.now() + ~~(Math.random() * 1000)}`,
      currentValue: null,
      currentConfig: {},
      currentEditor: null,
      hasInit: false
    }
  },
  watch: {
    value: {
      handler (val) {
        this.setContent(val)
      },
      immediate: true
    },
    currentValue (val) {
      let html = val
      if (html !== this.currentEditor.$txt.html()) {
        this.$nextTick(() => {
          this.currentEditor &&
            this.currentEditor.$txt &&
            this.currentEditor.$txt.html(html)
        })
      }
    }
  },
  methods: {
    setContent (val) {
      let setValueFunc = () => {
        if (val !== this.currentValue) {
          this.currentValue = val
        }
      }
      if (!this.hasInit) {
        this.$nextTick(() => {
          this.renderEditor()
          setValueFunc()
        })
      } else {
        setValueFunc()
      }
    },
    asyncValue (html) {
      this.$emit('input', html)
    },
    renderEditor () {
      let _this = this
      if (_this.hasInit) {
        return console.info('The editor is initialized')
      }
      let editor = new E(this.editorId)
      // Log configuration
      // E.config.printLog = false
      _this.editorConfig(editor)
      _this.imageConfig(editor)
      // Merge configuration
      this.currentConfig = assign(
        editor.config,
        this.globalOptions.config,
        _this.config
      )
      // console.log(editor.config)
      _this.hasInit = true
      editor.onchange = () => {
        let html = _this.currentEditor.$txt.html()
        _this.asyncValue(html)
      }
      editor.create()
      this.currentEditor = editor
    },
    editorConfig (editor) {
      // Editor configuration
      // Custom menu configuration
      editor.config.menus = [
        'source',
        '|',
        'bold',
        'underline',
        'italic',
        'strikethrough',
        'eraser',
        'forecolor',
        'bgcolor',
        '|',
        'quote',
        'fontfamily',
        'fontsize',
        'head',
        'unorderlist',
        'orderlist',
        'alignleft',
        'aligncenter',
        'alignright',
        '|',
        'link',
        'unlink',
        'table',
        // 'emotion',
        '|',
        'img',
        'video',
        'insertcode',
        '|',
        'undo',
        'redo',
        'fullscreen'
      ]
      editor.config.withCredentials = false
      editor.config.uploadImgFileName = 'file'
      // editor.config.uploadImgUrl = ''
      editor.config.uploadParams = {}
      editor.config.uploadHeaders = {}
      editor.config.zindex = 100
      editor.config.pasteFilter = true
      editor.config.menuFixed = false
    },
    imageConfig (editor) {
      // Editor upload image configuration
      let _this = this
      // Custom load event
      editor.config.uploadImgFns.onload = (resultText, xhr, fileName) => {
        if (xhr.status !== 200) {
          let msg = _this._uploadHandler('error')
          uploadError.call(
            this,
            fileName,
            msg || this.config.lang.uploadErrorPlaceTxt
          )
          return
        }
        let fileUrl = _this._uploadHandler('success', resultText)
        let $img = this.$txt.find(
          `img[alt=${this.config.lang.uploadPlaceTxt}${fileName}""]`
        )
        if (fileUrl) {
          if ($img.length > 0) {
            $img.attr('src', fileUrl).removeAttr('alt')
            this.$txt.change()
          } else {
            this.command(null, 'insertHtml', `<img src="${fileUrl}"/>`)
          }
        } else {
          uploadError.call(this, fileName, this.config.lang.uploadErrorPlaceTxt)
        }
      }

      // Custom timeout event
      editor.config.uploadImgFns.ontimeout = (xhr, fileName) => {
        let msg = _this._uploadHandler('timeout')
        uploadError.call(
          this,
          fileName,
          msg || this.config.lang.uploadTimeoutPlaceTxt
        )
      }

      // Custom error event
      editor.config.uploadImgFns.onerror = (xhr, fileName) => {
        let msg = _this._uploadHandler('error')
        uploadError.call(
          this,
          fileName,
          msg || this.config.lang.uploadErrorPlaceTxt
        )
      }
      function uploadError (fileName, msg) {
        let $img = this.$txt.find(
          `img[alt=${this.config.lang.uploadPlaceTxt}${fileName}"]`
        )
        $img.after(`【${msg}${fileName}】`)
        $img.remove()
        this.$txt.change()
      }
    },
    _uploadHandler (type, resultText) {
      let _this = this
      if (_this.uploadHandler) {
        return _this.uploadHandler(type, resultText)
      } else if (_this.globalOptions.uploadHandler) {
        return _this.globalOptions.uploadHandler(type, resultText)
      } else if (type === 'success') return resultText
      return null
    }
  }
}
</script>

<style lang="scss">
.yimo-vue-editor {
  width: 100%;
  height: auto !important;
  min-height: 300px;
  /* max-height: 500px; */
}
.wangEditor-fullscreen { overflow-y:auto;padding-top:32px !important;}
.wangEditor-fullscreen .wangEditor-menu-container { position:fixed; top:0;left:0}
</style>
