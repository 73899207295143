import { state } from './state'
import { getters, GETTERS } from './getters'
import { actions, ACTIONS } from './actions'
import { mutations, MUTATIONS } from './mutations'

export const DESIGN = {
  GETTERS,
  ACTIONS,
  MUTATIONS
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
