import { Store, USER } from '@/store'
import authViewer from './authViewer'
import services from '@/services'

export default async function authDesign ({ next, router, to }) {
  try {
    await services.cms.validateConnection()
    const cmsUsers = Object.values(Store.state.cms.user || {}).length
    if (!cmsUsers) {
      throw new Error()
    }
    return authViewer({ next, router, to })
  } catch (e) {
    const response = await Store.dispatch({ type: USER.ACTIONS.LOGOUT, router })
    return response
  }
}
