import { addNamespaceInOperations } from '../helper'

export const GETTERS_METHODS = {
  GET_VIEW: 'getView',
  GET_VIEWS: 'getViews',
  GET_ALL_VIEWS: 'getAllViews',
  GET_AVAILABLE_COMPONENTS: 'getAvailableComponents',
  GET_AVAILABLE_COMPONENT_BY_TYPE: 'getAvailableComponentsByType',
  GET_ALL_FILES: 'getAllFiles',
  GET_FILE_CONTENT: 'getFileContent'
}

export const getters = {
  [GETTERS_METHODS.GET_ALL_FILES]: (state) => () => {
    return Object.keys(state.files)
  },
  [GETTERS_METHODS.GET_FILE_CONTENT]: (state) => (filename) => {
    return state.files[filename]
  },
  [GETTERS_METHODS.GET_AVAILABLE_COMPONENTS]: (state) => () => {
    return state.internalComponents.concat(state.externalComponents)
  },
  [GETTERS_METHODS.GET_AVAILABLE_COMPONENT_BY_TYPE]: (state) => (type) => {
    return state.internalComponents.concat(state.externalComponents).find(e => e.id === type)
  },
  [GETTERS_METHODS.GET_VIEW]: (state) => (id) => {
    return state.views[id]
  },
  [GETTERS_METHODS.GET_VIEWS]: (state) => () => {
    return state.views
  },
  [GETTERS_METHODS.GET_ALL_VIEWS]: (state) => () => {
    return Object.keys(state.views)
  }
}

export const GETTERS = addNamespaceInOperations('design', GETTERS_METHODS)
