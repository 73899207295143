import { pluginFactory } from '@/plugins/plugin'
import BasicChart, { TYPE } from './BasicChart.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const BasicChartPlugin = pluginFactory({
  components: { BasicChart }
})

export { BasicChartPlugin, BasicChart, TYPE }
export default BasicChart
