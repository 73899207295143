import { pluginFactory } from '@/plugins/plugin'
import Connections, { TYPE } from './Connections.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const ConnectionsPlugin = pluginFactory({
  components: { Connections }
})

export { ConnectionsPlugin, Connections, TYPE }
export default Connections
