import { pluginFactory } from '@/plugins/plugin'
import CodeHtml, { TYPE } from './CodeHtml.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const CodeHtmlPlugin = pluginFactory({
  components: { CodeHtml }
})

export { CodeHtmlPlugin, CodeHtml, TYPE }
export default CodeHtml
