import { addNamespaceInOperations } from '../helper'

export const GETTERS_METHODS = {
  GET_USER_TO_CONNECTION: 'getTokenByConnection',
  GET_ALL_USERS: 'getAllUsers'
}

export const getters = {
  [GETTERS_METHODS.GET_USER_TO_CONNECTION]: (state) => (id) => {
    return (state.users || {})[id]
  },
  [GETTERS_METHODS.GET_ALL_USERS]: (state) => (id) => {
    return (state.users || {})
  }
}

export const GETTERS = addNamespaceInOperations('user', GETTERS_METHODS)
