import { addNamespaceInOperations } from '../helper'

export const GETTERS_METHODS = {
  GET_USER: 'GET_USER',
  GET_TEMPORARY_TABLES: 'GET_TEMPORARY_TABLES'
}

export const getters = {
  [GETTERS_METHODS.GET_USER]: (state) => () => state.user || {}
}

export const GETTERS = addNamespaceInOperations('cms', GETTERS_METHODS)
