import { addNamespaceInOperations } from '../helper'

export const GETTERS_METHODS = {
  GET_FILTERS: 'getFilters',
  HASH_FILTERS: 'hashFilters'
}

export const getters = {
  [GETTERS_METHODS.HASH_FILTERS]: (state) => (filter = []) => {
    if (!filter.length) return state.filters
    const filtersReduce = {}
    filter.forEach(idElement => {
      if (state.filters[idElement]) {
        filtersReduce[idElement] = state.filters[idElement]
      }
    })
    return filtersReduce
  },
  [GETTERS_METHODS.GET_FILTERS]: (state, getters) => (filter = []) => {
    return Object.values(getters[GETTERS_METHODS.HASH_FILTERS](filter)).flat(1)
  }
}

export const GETTERS = addNamespaceInOperations('plaka', GETTERS_METHODS)
