import { addNamespaceInOperations } from '../helper'
import { TYPE } from '@/services/connection'
import { MUTATIONS_METHODS } from './mutations'
import { LOGIN_PATH } from '@/enviroment/app'

export const ACTIONS_METHODS = {
  LOGIN: 'loginPlaka',
  LOGOUT: 'logout'
}

const loginPlaka = ({ connection, username, password, token }) => {
  if (!connection.login) {
    throw new Error(`Not defined login in connection type 'connection.type'`)
  }
  return connection.login({ username, password, token }).then((data) => {
    return Promise.resolve(data)
  })
}

export const actions = {
  [ACTIONS_METHODS.LOGIN] ({ commit }, { connection = {}, username, password, token }) {
    return new Promise(async (resolve, reject) => {
      let user
      switch (connection.type) {
        case TYPE.PLAKA:
          user = await loginPlaka({ connection, username, password, token })
          break
        default:
          reject(new Error(`Type '${connection.type}' is not a valid type`))
      }
      commit(MUTATIONS_METHODS.SET_USER, {
        id: connection.id,
        user
      })
      resolve(user)
    })
  },
  [ACTIONS_METHODS.LOGOUT] ({ commit, state }, { router }) {
    if (state.authPage) {
      const authPage = state.authPage
      commit({ type: MUTATIONS_METHODS.AUTH_PAGE, url: undefined })
      window.location.href = authPage
    } else {
      return router.push({ name: LOGIN_PATH })
    }
  }
}

export const ACTIONS = addNamespaceInOperations('user', ACTIONS_METHODS)
