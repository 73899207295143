<template>
  <v-select :placeholder="$t(plkparams.placeholder)" :taggable="plkparams.taggable" :disabled="loading" :loading="loading" class="plk-basic-filter" :multiple="plkparams.multiple" v-model="selected" :options="values" label="value">
    <template #header>
      <div class="title">{{plkparams.title}}</div>
    </template>
    <template #selected-option="e">
      <div style="display: flex; align-items: baseline">
        <strong>{{hashDimensions[e.filter]?.value}}</strong>
      </div>
    </template>
    <template #list-header>
      <li @click="clearAll()" class="clearall" v-if="selected.length">{{$t('plk.basicfilter.clearAll')}}</li>
    </template>
    <template #spinner="{ loading }">
      <div v-if="loading" class="vs__spinner loader"></div>
    </template>
    <template #no-options>
      <div v-if="plkparams.taggable && !selected.length">{{$t('plk.basicfilter.taggable')}}</div>
      <div v-if="!plkparams.taggable && !selected.length">{{$t('plk.basicfilter.notOptions')}}</div>
    </template>
  </v-select>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import vSelect from 'vue-select'
import services from '@/services'
import configuration from './package.json'
import readme from './README.md'
import 'vue-select/dist/vue-select.css'
import { Store, PLAKA } from '@/store'
import BasicComponentMixin from '@/mixins/viewer/components/base'
import OrderComponentMixin from '@/mixins/viewer/components/order'
export const TYPE = services.component.normalizeType(configuration.type)

export default {
  name: TYPE,
  mixins: [
    BasicComponentMixin,
    OrderComponentMixin
  ],
  data () {
    return {
      hashDimensions: {},
      selected: undefined,
      loading: false,
      externalFilters: false
    }
  },
  components: {
    'v-select': vSelect
  },
  computed: {
    dimensionValue () {
      return Object.values(this.hashDimensions)
    },
    options () {
      return this.plkparams.options || {}
    },
    conditions () {
      return this.plkparams.conditions
    },
    values () {
      return (this.options.list || []).concat(this.dimensionValue)
    },
    filters () {
      if (this.connectionStarted && this.options.dimension && Store.getters[PLAKA.GETTERS.GET_FILTERS]([this.options.dimension.idElement]).length) {
        return Store.getters[PLAKA.GETTERS.GET_FILTERS]([this.options.dimension.idElement])[0].values.map(e => ({ filter: e, value: e }))
      } else {
        return []
      }
    },
    labels () {
      if (this.options.labels && Object.keys(this.options.labels).length && this.options.labels.idElement) {
        return this.options.labels
      }
      return undefined
    }
  },
  methods: {
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    },
    clearAll () {
      this.clearFilter(this.options.dimension)
    },
    onFilter (filters) {
      const filterArr = (Array.isArray(filters) ? filters : [filters])
      if (!filters || !filterArr.length) {
        this.clearAll()
      } else {
        let dimension = _.cloneDeep(this.options.dimension)
        dimension.values = filterArr.map(f => f.filter === undefined && f.value === undefined ? f : f.filter === undefined ? f.value : f.filter).flat()
        this.getConnection().applyFilters([dimension], { overwrite: true })
      }
    },
    async startData () {
      if (this.getConnection() && this.options.dimension && this.options.loadDimensionValues !== false) {
        Vue.set(this, 'loading', true)
        const dimensions = this.labels ? [this.options.dimension, this.labels] : [this.options.dimension]
        let values = await this.getConnection().getDataByPage(dimensions, this.conditions, { order: this.order })

        const labels = values.getDimensionValues((this.labels || this.options.dimension).idElement)
        const element2filter = values.getDimensionValues(this.options.dimension.idElement)
        if (labels.length !== element2filter.length) {
          console.warn('Different number of elements between labels and filterElements', labels.length, element2filter.length)
        } else {
          const hashDimensions = {}
          labels.forEach((e, index) => {
            hashDimensions[element2filter[index]?.id] = { filter: `${element2filter[index]?.id}`, value: e.id }
          })
          Vue.set(this, 'hashDimensions', hashDimensions)
          Vue.set(this, 'loading', false)
        }
      } else {
        Vue.set(this, 'loading', false)
      }
    }
  },
  watch: {
    connectionStarted () {
      if (this.connectionStarted && this.getConnection().type === this.$servicesPLK.connection.TYPE.PLAKA) {
        this.startData()
      }
    },
    selected (filters) {
      if (!this.externalFilters) {
        this.onFilter(filters)
      } else {
        Vue.set(this, 'externalFilters', false)
      }
    },
    filters () {
      Vue.set(this, 'externalFilters', true)
      Vue.set(this, 'selected', this.filters)
    }
  }
}
</script>

<style lang="scss" scoped>
  .plk-basic-filter {
    li {
      margin-bottom: 0rem;
      &:hover {
        background: #f0f0f0;
        color: black;
      }
      &:focus {
        background: #f0f0f0;
        color: black;
      }
      &:active {
        background: #f0f0f0;
        color: black;
      }
      &:visited {
        background: #f0f0f0;
        color: black;
      }
      &:focus-within {
        background: #f0f0f0;
        color: black;
      }
    }
    .loader {
      border-left-color: grey;
    }
    .title {
      opacity: .8;
    }
    .clearall {
      text-align: center;
      cursor: pointer;
    }
  }
</style>
