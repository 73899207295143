import Vue from 'vue'
import i18n from './i18n'
export default {
  data () {
    return {
      packages: {},
      readmeList: {}
    }
  },
  beforeCreate () {
    this.$servicesPLK.translation.init(i18n, { overwrite: false })
  },
  metaInfo () {
    return {
      title: this.$t(`designer.title.${this.$route.name}`),
      htmlAttrs: {
        lang: navigator['language'] || navigator['userLanguage'],
        amp: true
      }
    }
  },
  methods: {
    async loadPackage (type) {
      if (type && !this.packages[type]) {
        const componentPackage = await this.$servicesPLK.component.loadPackage(type)
        Vue.set(this.packages, type, componentPackage)
      }
    },
    async loadReadme (type) {
      if (type) {
        const loadedReadme = await this.$servicesPLK.component.loadReadme(type)
        Vue.set(this.readmeList, type, loadedReadme)
      }
    }
  },
  watch: {
  }
}
