import { pluginFactory } from '@/plugins/plugin'
import HelloWorld, { TYPE } from './HelloWorld.vue'
import services from '@/services'
import i18n from './i18n'

services.translation.init(i18n, { overwrite: false })

const HelloWorldPlugin = pluginFactory({
  components: { HelloWorld }
})

export { HelloWorldPlugin, HelloWorld, TYPE }
export default HelloWorld
