<template>
    <div>
      <select :id="id" v-model="model" :disabled="plkparams.disabled" :required="plkparams.required" class="u-full-width" :placeholder="$t(plkparams.placeholder)">
        <option v-for="(option, index) in plkparams.options || []" :key="index" :value="option.value">
          {{$t(option.item)}}
        </option>
      </select>
    </div>
</template>

<script>
import fields from '@/mixins/viewer/types/fields'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  mixins: [
    fields
  ],
  methods: {
    onInitComponent () {
      this.setModel()
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  }
}
</script>

<style scoped lang="scss">
</style>
