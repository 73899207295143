<template>
    <div class="select_conditions" v-if="model">
      <div class="options">
        <input :placeholder="$t('configuration.types.conditions.search')" class="w-100" type="text" :value="search" @input="e => search = e.target.value" @focus="searching = true" @blur="searching = false"/>
        <div v-if="search" class="items">
          <div @click="addElement(element)" class="item selectable" v-for="(element, index) in conditions" :key="index" :class="{ 'disabled': selectedCube && selectedCube !== element.cube }" :title="selectedCube && selectedCube !== element.cube ? $t('configuration.types.conditions.notposiblejoindata') : $t('configuration.types.conditions.click2add')">
            <div class="elementId">
              <span class="suptitle">{{element.cube}}</span>
              <span class="label">{{element.label}}</span>
            </div>
            <i class="fas fa-plus add pointer"></i>
          </div>
          <div class="item text-center selectable" v-if="!conditions.length">
            {{$t('configuration.types.conditions.notconditions')}}
          </div>
        </div>
      </div>
      <draggable class="items" v-model="model" v-bind="dragOptions">
        <transition-group>
          <div :title="$t('configuration.types.conditions.drag2move')" class="item draggable" v-for="(element, index) in model" :key="element.idElement">
            <div class="elementId">
              <span class="suptitle" :title="selectedCube">{{selectedCube}}</span>
              <span class="label" :title="element.label">{{element.label}}</span>
            </div>
            <v-select :options="columnValues[element.idElement]" v-model="element.values" class="w-100" :class="{ 'mt-2': element.values === undefined || element.values.length === 0 }" :placeholder="$t('configuration.types.conditions.value')" taggable multiple />
            <i @click="removeIndex(index)" class="fas fa-times remove pointer"></i>
          </div>
        </transition-group>
      </draggable>
      <div class="item text-center selectable" v-if="!connectionObject">
        {{$t('configuration.types.conditions.notselectedConnection')}}
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import fields from '@/mixins/viewer/types/fields'
import { ELEMENT_TYPES } from '@/services/connection/plaka/JPlakaProcess'
import draggable from 'vuedraggable'
import configuration from './package.json'
import readme from './README.md'
import services from '@/services'
import _ from 'lodash'
export const TYPE = services.component.normalizeType(configuration.type)
export default {
  name: TYPE,
  mixins: [
    fields
  ],
  components: {
    draggable,
    vSelect
  },
  data () {
    return {
      searching: false,
      search: '',
      connectionId: undefined,
      connectionObject: undefined,
      columns: [],
      measures: [],
      columnValues: {}
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    conditions () {
      const search = (this.search || '').toUpperCase()
      const conditions = (this.columns || []).concat(this.measures || [])
      if (search === '*') return conditions.filter(e => !this.model.find(s => s.label === e.label))
      return conditions.filter(e => e.label.toUpperCase().indexOf(search) !== -1 && !this.model.find(s => s.label === e.label))
    }
  },
  methods: {
    onInitComponent () {
      this.loadConnectionName()
      this.setModel()
    },
    removeIndex (index) {
      if (index > -1) {
        let tempModel = _.cloneDeep(this.model)
        tempModel.splice(index, 1)
        Vue.set(this, 'model', tempModel)
        this.$emit('unselectCube')
      }
    },
    addElement ({ idElement, label, type, cube }) {
      if (idElement && type) {
        let tempModel = _.cloneDeep(this.model)
        tempModel.push({ idElement, label, type })
        Vue.set(this, 'model', tempModel)
        Vue.set(this, 'search', '')
        this.$emit('selectCube', { cube })
      }
    },
    loadConnectionName () {
      const globalParam = this.getGlobalParam(this.plkparams.connection)
      Vue.set(this, 'connectionId', globalParam === undefined ? this.plkparams.connection : globalParam)
    },
    async getColumns () {
      Vue.set(this, 'columns', [])
      if ((!this.plkparams.element || this.plkparams.element === ELEMENT_TYPES.DIMENSION) && this.connectionObject) {
        const _columns = await this.connectionObject.getDimensions()
        Vue.set(this, 'columns', _columns)
      }
    },
    async getMeasures () {
      Vue.set(this, 'measures', [])
      if ((!this.plkparams.element || this.plkparams.element === ELEMENT_TYPES.MEASURE) && this.connectionObject) {
        const _measures = await this.connectionObject.getMeasures()
        Vue.set(this, 'measures', _measures)
      }
    },
    loadColumnValues () {
      if ((this.plkparams.showValueOptions === undefined || this.plkparams.showValueOptions) && this.connectionObject) {
        this.model.forEach(async column => {
          if (!this.columnValues[column.idElement] && column.type === ELEMENT_TYPES.DIMENSION) {
            const values = await this.connectionObject.getDimensionValues(column)
            Vue.set(this.columnValues, column.idElement, (values.data.dimension[column.idElement].index || []).map(e => e.id))
          }
        })
      }
    },
    getPackage () {
      return configuration
    },
    getReadme () {
      return readme
    }
  },
  watch: {
    model () {
      this.loadColumnValues()
    },
    global () {
      this.loadConnectionName()
    },
    async connectionId () {
      if (this.connectionId) {
        const _connectionApi = await this.$servicesPLK.connection.getConnection(this.connectionId)
        Vue.set(this, 'connectionObject', _connectionApi)
      }
    },
    connectionObject (newValue, oldValue) {
      if (oldValue && newValue.id !== oldValue.id) {
        Vue.set(this, 'model', [])
      }
      this.getColumns()
      this.getMeasures()
      this.loadColumnValues()
    }
  }
}
</script>

<style scoped lang="scss">
  .select_conditions {
    .remove {
      &:hover {
        color: #900c3e;
      }
    }
    .add {
      display: none;
      color: green;
    }
    .fas {
      line-height: 33px;
    }
    .items {
      .item {
        padding: 2px 10px;
        border: solid 1px #efefef;
        border-radius: 4px;
        display: flex;
        height: fit-content;
        justify-content: flex-start;
        gap: 3rem;
        margin-top: 5px;

        &.selectable {
          margin-top: 0;
          cursor: pointer;
          border: 0;
          font-weight: 600;
          &:hover {
            background: #efefef;
          }
          &.disabled{
            cursor: no-drop;
            color: #cecece;
            &:hover {
              background: none;
            }
          }
          &:hover {
            .add {
              margin-left: auto;
              display: block;
              line-height: 33px;
              line-height: 33px;
            }
          }
        }
        .elementId {
          display: flex;
          flex-direction: column;
          max-width: 50%;
          min-width: 15%;

          .suptitle {
            font-size: .9rem;
            font-weight: lighter;
          }

          .label {
            margin-top: -5px;;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .remove {
          line-height: 33px;
          margin-left: auto;
        }
      }
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #efefef;
  }
</style>
